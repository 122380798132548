import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { getAbsence, getAbsenceInfo } from "../../redux/absence";
import { MenuItem, Select } from "@mui/material";
import defaultAPI from "../../axiosInstance";
import { useSidebarContext } from "../global/sidebar/sidebarContext";

const EditAbsence = ({ icon, sx, id, selectedClassId, pageSize }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("");
  const dispatch = useDispatch();
  const { sidebarRTL } = useSidebarContext();

  const handleClickOpen = () => {
    setOpen(true);
    dispatch(getAbsenceInfo({ id })).then((res) => {
      setType(res.payload.data.type);
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = async () => {
    const baseUrl = `/absence/${id}`;
    await defaultAPI.put(baseUrl, { type }).then(res => {
      dispatch(getAbsence({ id: selectedClassId, pageSize: pageSize }))
    })
    setOpen(false)
  }

  return (
    <div>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        sx={sx}
        endIcon={icon}
      >
        {t("Edit")}
      </Button>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        sx={{
          width: { xs: "100%", md: "50%" },
          margin: "auto",
          padding: "0",
        }}
      >
        <DialogTitle sx={{ fontWeight: "bold", fontSize: "22px" }} align={sidebarRTL ? "right" : "left"}>{t("Edit")}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>{t("Edit Absence")}</DialogContentText> */}
          <Select
            value={type}
            onChange={(e) => setType(e.target.value)}
            fullWidth
            label={t("Select Option")}
            dir={sidebarRTL ? "rtl" : "ltr"}
          >
            <MenuItem dir={sidebarRTL?"rtl":"ltr"} value="justified_absence">{t("justified_absence")}</MenuItem>
            <MenuItem dir={sidebarRTL?"rtl":"ltr"} value="unjustified_absence">{t("unjustified_absence")}</MenuItem>
            <MenuItem dir={sidebarRTL?"rtl":"ltr"} value="attendance">{t("attendance")}</MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            {t("Cancel")}
          </Button>
          <Button variant="contained" onClick={handleEdit}>
            {t("Edit")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditAbsence;
