import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import DefaultButton from "./defaultBtn";
import defaultAPI from "../../axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import { AllStudentsExpensesData } from "../../redux/StudentsExpenses";
import { dashboardGrades } from "../../redux/dashboard";
import { getStudentInfoByGrade } from "../../redux/student";
import MenuItem from "@mui/material/MenuItem";
import { Select, FormControl } from "@mui/material";
import MultiSelectMenu from "./multiSelect";
import { getGradeExpenses } from "../../redux/Grades";
import EditMultiSelectMenu from "./EditmultiSelect";

const validationSchema = Yup.object().shape({
  student_id: Yup.number().required("Student is required"),
});

const EditStudentsExpenses = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const { sidebarRTL } = useSidebarContext();

  // Removed unused state variables
  const [selectedGradeId, setSelectedGradeId] = useState(null);

  // Removed unused state variables
  const [selectedStudnetId, setSelectedStudnetId] = useState(props.id);

  // Added selectedOptions state
  const [selectedOptions, setSelectedOptions] = useState([]);

  const gradeExpensesdata =
    useSelector((state) => state.dashboard.grades) || [];
  const studentData =
    useSelector((state) => state.student.student_menu.data) || [];
  const StudnetGradeExpenses =
    useSelector((state) => state.grades.studentGradeExpenses.data) || [];
  useEffect(() => {
    dispatch(dashboardGrades());
  }, [dispatch]);

  const info = {
    pageSize:props.pageSize
  };

  const formik = useFormik({
    initialValues: {
      student_id: props.id,
      grade_expenses: props.grades,
    },
    validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = async (values) => {
    const selectedExpenseIds = selectedOptions.map((option) => option.id);
    values.grade_expenses = selectedExpenseIds;
    values.student_id = selectedStudnetId;
    // Assign the selected expense IDs to the grade_expenses field in values

    try {
      const res = await defaultAPI.put("/student_grade_expenses", values);
      if (res.status === 200) {
        dispatch(AllStudentsExpensesData(info)).then(()=>handleClose())

      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSelectChangeGrade = async (event) => {
    const selectedGradeId =event.tagret.value
    setSelectedGradeId( event.target.value);
      await dispatch(getStudentInfoByGrade(selectedGradeId))
  };

  const handleSelectChangeStudent = async (event) => {
    const selectedStudnetId = event.target.value;
    setSelectedStudnetId(event.target.value);

      await dispatch(getGradeExpenses({ id: selectedStudnetId }));

  };

  const handleSelectedOptions = (selected) => {
    setSelectedOptions(selected);
  };
  const handleClose = () => {
    setSelectedGradeId(null)
    setOpen(false)
    formik.resetForm();
  }
  useEffect(() => {
    if(props.grade){
      dispatch(getStudentInfoByGrade(props.grade))
      if(props.id){
        dispatch(getGradeExpenses({ id: props.id }));
      }
    }
  }, [props.grade,props.id]);
  return (
    <div>
      <Button
        variant="contained"
        sx={props.sx}
        onClick={handleClickOpen}
        endIcon={props.icon}
      >
                {t("Edit")}
            </Button>
      <Dialog
        fullWidth={true}
        sx={{ width: { xs: "100%", md: "50%" }, margin: "auto", padding: "0" }}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle
          sx={{ fontWeight: "bold", fontSize: "22px" }}
          align={sidebarRTL ? "right" : "left"}
        >
          {t("Edit")}
        </DialogTitle>
        <DialogContent dir={sidebarRTL ? "rtl" : "ltr"}>
          <form onSubmit={formik.handleSubmit}>
            {/* <FormControl variant="outlined" sx={{ width: "100%" }}>
              <label style={{ fontSize: "18px", fontWeight: "bold" }}>
                {t("Grade")}
              </label>
              <Select
                fullWidth
                value={selectedGradeId||props.grades}
                onChange={handleSelectChangeGrade}
                dir={sidebarRTL ? "rtl" : "ltr"}
              >
                {gradeExpensesdata &&
                  gradeExpensesdata.map((gradeItem) => (
                    <MenuItem
                      dir={sidebarRTL ? "rtl" : "ltr"}
                      key={gradeItem.id}
                      value={gradeItem.id}
                    >
                      {gradeItem.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl> */}

            <FormControl variant="outlined" sx={{ width: "100%" }}>
              <label style={{ fontSize: "18px", fontWeight: "bold" }}>
                {t("Select Student")}
              </label>
              <Select
                fullWidth
                disabled="true"
                value={formik.values.student_id} // Provide an empty string as a fallback
                onChange={handleSelectChangeStudent}
                dir={sidebarRTL ? "rtl" : "ltr"}
                name="student_id"
              >
                {studentData.length > 0 &&
                  studentData.map((studentItem) => (
                    <MenuItem
                      dir={sidebarRTL ? "rtl" : "ltr"}
                      key={studentItem.id}
                      value={studentItem.id}
                    >
                      {studentItem.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <EditMultiSelectMenu
              name="grade_expenses"
              data={StudnetGradeExpenses}
              text={"StudentsExpenses"}
              onSelectedOptions={handleSelectedOptions}
              studentId={props.id}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
            onClick={handleClose}
          >
            {t("Cancel")}
          </Button>
          <Button
            type="submit"
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 15px",
            }}
            onClick={() => handleSubmit(formik.values)}
            endIcon={props.icon}
          >
            {t("Edit")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditStudentsExpenses;