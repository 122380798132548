import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
    useTheme,
  } from "@mui/material";
  import { useFormik } from "formik";
  import * as yup from "yup";
  import { useMediaQuery } from "@mui/material";
  import { tokens } from "../../theme";
  import { useDispatch, useSelector } from "react-redux";
  import { useTranslation } from "react-i18next";
  import {  useState } from "react";
  import { addOneGradeExpenses, EditOneGradeExpenses, getOneGradeExpenses } from "../../redux/gradeExpenses";
  import {  selectExpenses } from "../../redux/gradeExpenses";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
  
  const EditGradeExpenses = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const { sidebarRTL } = useSidebarContext();
    const loading = useSelector((state) => state.loading);
  
    const checkoutSchema = yup.object().shape({
      price: yup.number().required("Required"),
    });
  console.log(props.price.split(' ')[0].replace(/,/ig,""));
    const formik = useFormik({
      initialValues: {
        price: props.price.split(' ')[0].replace(/,/ig,""),
      },
      validationSchema: checkoutSchema,
      onSubmit: (values) => {
        handleFormSubmit(values);
      },
    });
  
    const [open, setOpen] = useState(false);
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = async () => {
      const info = {
        id: props.gradeExpensesId,
        pageSize:props.pageSize
      }
      // await dispatch(getOneGradeExpenses(info))
      setOpen(false);
    };
  
    const handleFormSubmit = async (values) => {
      const info = {
        id: props.id,
        value:values
    }
      await dispatch(EditOneGradeExpenses(info)).then((res)=>res.payload.code===200 && handleClose())
    };
  
    return (
      <Box m="20px" >
         <Button
                variant="contained"
                sx={props.sx}
                onClick={handleClickOpen}
                endIcon={props.icon}
            >
                {t("Edit")}
            </Button>
        <Dialog
          sx={{ width: { xs: "100%", md: "50%" }, margin: "auto", padding: "0" }}
          open={open}
          onClose={handleClose}
        >
          <DialogContent dir={sidebarRTL?"rtl":"ltr"}>
            <DialogTitle sx={{ fontWeight: "bold", fontSize: "22px" }} align={sidebarRTL ? "right" : "left"}>{t("edit", " ", "prices")}</DialogTitle>
            <form onSubmit={formik.handleSubmit} dir={sidebarRTL?"ltr":"rtl"}>
              <Box
                dir={sidebarRTL?"rtl":"ltr"}
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <lable style={{fontSize:"18px",fontWeight: "Bold",width:"10rem"}}>{t("amount")}</lable>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  placeholder={t("amount")}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.price}
                  name="price"
                  error={!!formik.touched.price && !!formik.errors.price}
                  helperText={formik.touched.price && formik.errors.price}
                  dir={sidebarRTL ? "rtl" : "ltr"}
                  sx={{ gridColumn: "span 10" }}
                  inputProps={{
                    style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
                  }}
                />
              </Box>
              <Box display="flex" justifyContent="end" mt="20px">
                <Button
                  type="submit"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px",
                  }}
                  disabled={loading ? true : false}
                  variant="outlined"
                  onClick={handleClickOpen}
                >
                  {loading ? t("wait") : t("Edit")}
                </Button>
              </Box>
            </form>
          </DialogContent>
        </Dialog>
      </Box>
    );
  };
  
  export default EditGradeExpenses;
  