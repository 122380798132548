import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  editExam,
  fetchExamsData,
} from "../../redux/Exams";
import { tokens } from "../../theme";
import { useSidebarContext } from "../global/sidebar/sidebarContext";

const EditExam = (props) => {
  const { sidebarRTL } = useSidebarContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const loading = useSelector((state) => state.exams.loading);

  const handleFormSubmit = (values) => {
    const info = {
      values: values,
      id: props.ID,
    };
    const redispatchInfo = {
      pageSize: props.pageSize,
    };
    dispatch(editExam(info)).then(() => {
      dispatch(fetchExamsData(redispatchInfo));
    });
    setOpen(false);
  };

  const initialValues = {
    name: props.name,
    default_full_mark: props.defaultFullMark,
  };

  const checkoutSchema = yup.object().shape({
    name: yup.string().required("Required"),
    default_full_mark: yup.number().required("Required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: checkoutSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box m="20px">
      <Button
        variant="contained"
        sx={props.sx}
        endIcon={props.icon}
        onClick={handleClickOpen}
      >
        {t("Edit")}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ fontWeight: "bold", fontSize: "22px" }} align={sidebarRTL ? "right" : "left"}>{t("Edit")}</DialogTitle>
        <DialogContent dir={sidebarRTL?"rtl":"ltr"}>
          <form onSubmit={formik.handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": {
                  gridColumn: isNonMobile ? undefined : "span 4",
                },
              }}
            >
              <Box display="flex" flexDirection="column" sx={{gridColumn: "span 2"}}>
                  <lable style={{fontSize:"18px",fontWeight: "Bold"}}>{t("Name")}</lable>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                placeholder={t("Name")}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                name="name"
                error={formik.touched.name && formik.errors.name}
                helperText={formik.touched.name && formik.errors.name}
                sx={{ gridColumn: "span 2" }}
                dir={sidebarRTL ? "rtl" : "ltr"}
                inputProps={{
                  style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
                }}
                />
              </Box>
              <Box display="flex" flexDirection="column" sx={{gridColumn: "span 2"}}>
                  <lable style={{fontSize:"18px",fontWeight: "Bold"}}>{t("Default Full Mark")}</lable>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                  placeholder={t("Default Full Mark")}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.default_full_mark}
                name="default_full_mark"
                error={
                  formik.touched.default_full_mark &&
                  formik.errors.default_full_mark
                }
                helperText={
                  formik.touched.default_full_mark &&
                  formik.errors.default_full_mark
                }
                sx={{ gridColumn: "span 2" }}
                dir={sidebarRTL ? "rtl" : "ltr"}
                inputProps={{
                  style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
                }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                sx={{
                  backgroundColor: colors.blueAccent[700],
                  color: colors.grey[100],
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 20px",
                }}
                disabled={loading}
                variant="outlined"
              >
                {loading ? "wait..." : t("Edit")}
              </Button>
              </Box>
              </Box>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default EditExam;
