import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import {
  getExams,
  getFullMarkGrades,
  getFullMarkSubjects,
} from "../../redux/FullMarks";
import {
  Select,
  FormControl,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSidebarContext } from "../global/sidebar/sidebarContext";

const DropDownGradesSubjects = ({ setFormikValues }) => {
  const {t} = useTranslation()
  const [selectedGradeId, setSelectedGradeId] = useState();
  const [SelectedExamType, setSelectedExamType] = useState("");
  const [selectedSubject, setselectedSubject] = useState();
  const AllGrades =
    useSelector((state) => state.FullMarks.getGradesData.data) || [];
  
  const dispatch = useDispatch();
const subjects =useSelector(state=>state.FullMarks.getSubjectsData.data)||[]
  useEffect(() => {
    dispatch(getFullMarkGrades());
    dispatch(getExams());
  }, [dispatch]);

  useEffect(() => {
    if (selectedGradeId) {
      dispatch(getFullMarkSubjects({ selectedGradeId }));
    }
  }, [dispatch, selectedGradeId]);

  const handleSelectChangeGrade = (event) => {
    const selectedGradeId = event.target.value;
    setSelectedGradeId(selectedGradeId);
    setselectedSubject(null);
    setFormikValues("grade_id", selectedGradeId);
  };

  const ExamsTypeArray = [`${t("mid-term")}`, `${t("final")}`]


  const handleSelectChangeSubject = (event) => {
    setselectedSubject(event.target.value);
    setFormikValues("subject_id", event.target.value);
  };
  const handleSelectChangeType = (event) => {
    setSelectedExamType(event.target.value);
    setFormikValues("type", event.target.value);
  };


  const { sidebarRTL } = useSidebarContext();
  return (
    <Box m="20px" display="flex" flexDirection="column" dir={sidebarRTL?"rtl":"ltr"}>
      <FormControl variant="outlined">
        <lable style={{fontSize:"18px",fontWeight: "Bold"}}>{t("Grade")}</lable>
        <Select
          fullWidth
          value={selectedGradeId}
          onChange={handleSelectChangeGrade}
          dir={sidebarRTL ? "rtl" : "ltr"}
        >
          {AllGrades &&
            AllGrades.map((gradeItem) => (
              <MenuItem dir={sidebarRTL ? "rtl" : "ltr"}
                key={gradeItem.id} value={gradeItem.id}>
                {gradeItem.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined" sx={{ mt: 2 }}>
        <lable style={{fontSize:"18px",fontWeight: "Bold"}}>{t("Subject Name")}</lable>
        <Select
          fullWidth
          value={selectedSubject}
          onChange={handleSelectChangeSubject}
          dir={sidebarRTL ? "rtl" : "ltr"}
        >
          {subjects &&
            subjects.map((item) => (
              <MenuItem dir={sidebarRTL ? "rtl" : "ltr"}
                key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined" sx={{ mt: 2 }}>
        <lable style={{fontSize:"18px",fontWeight: "Bold"}}>{t("ExamType")}</lable>
        <Select
          fullWidth
          value={SelectedExamType}
          onChange={handleSelectChangeType}
          dir={sidebarRTL ? "rtl" : "ltr"}
        >
          {ExamsTypeArray &&
            ExamsTypeArray.map((item,index) => (
              <MenuItem dir={sidebarRTL ? "rtl" : "ltr"}
                key={index} value={index}>
                {item}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default DropDownGradesSubjects;
