import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import CustomTableBox from "../../components/customTableBox/CustomTableBox";
import { parentSelectMenu } from "../../redux/parents";
import { getStudentInfoByParent } from "../../redux/student";
import { Link } from "react-router-dom";

const StudentInstallmentsTable = () => {
  const dispatch = useDispatch();
  const { sidebarRTL } = useSidebarContext();
  const [selectedParentId, setselectedParentId] = useState();

  const AllParents =
    useSelector((state) => state.parentData.ParentMenu.data) || [];
  const AllStudent =
    useSelector((state) => state.student.studentParent_menu.data) || [];

  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(10);
  const loading = useSelector((state) => state.gradeExpenses.loading);
  const theme = useTheme();

  const permission =
    useSelector((state) => state.user.userInfo.data.permissions) || [];
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    dispatch(parentSelectMenu());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getStudentInfoByParent(selectedParentId));
  }, [dispatch, selectedParentId, AllParents]);
  
  // const [selectedStudentId, setselectedStudentId] = selectedStudentIdContext(null);


  // const handleButtonClick = (id) => {
  //   setselectedStudentId
  // }
  const handleSelectChangeParent = (event) => {
    const selectedParentId = event.target.value; // Get the selected grade ID
    setselectedParentId(selectedParentId); // Update the selected grade ID in state
  };
  const handleClickPay = (e) => {
    // Check if the event object has a value property
    if (e && e.target && e.target.value) {
      
      // localStorage.setItem("SelectedStudentId", e.target.value);
    }
  };
  const expense =
    permission.includes("update-installments") &&
    permission.includes("delete-installments")
      ? 400
      : 400;
  const columns = [
    { field: "id", headerName: t("ID"), width: 200 },
    {
      field: "name",
      headerName: t("StudentsName"),
      width: expense,
      cellClassName: "name-column--cell",
    },
    ...(permission.includes("delete-installments") &&
    permission.includes("update-installments")
      ? [
          {
            field: "actions",
            headerName: t("Actions"),
            width: 250,
            renderCell: (params) => (
              <ButtonGroup
                sx={{
                  display: "flex",
                  flexDirection: sidebarRTL
                    ? "row-reverse !important"
                    : "row !important",
                  alignItems: "center",
                }}
              >
                <Link to={`/student-installments-info/${params.id}`}>
                  <Button
                    sx={{
                      background: `${colors.primary[600]}`,
                      textTransform: "capitalize",                  
                      onCLick:(e)=>handleClickPay(e),
                      display: permission.includes("update-installments")
                        ? "inline-flex"
                        : "none",
                    }}
                  variant="contained"
                  >
                  
                    {t("Pay")}
                  </Button>
                </Link>
              </ButtonGroup>
            ),
          },
        ]
      : []),
  ];
  const tableData = {
    rows: AllStudent.length > 0 && AllStudent,
    columns: columns,
    loading: loading && true,
    pageSize: pageSize,
    onPageSizeChange: (newPageSize) => setPageSize(newPageSize),
  };

  return (
    <Box m="20px">
      <Box
        display="flex"
        dir={sidebarRTL ? "rtl" : "ltr"}
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%" }}
      >
        <Header title={t("Student Installments")} />
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={sidebarRTL ? "row" : "row-reverse"}
      >
        <FormControl variant="outlined">
          <label style={{ fontSize: "18px", fontWeight: "bold" }}>
            {t("Parents")}
          </label>
          <Select
            fullWidth
            value={selectedParentId || ""}
            onChange={handleSelectChangeParent}
            dir={sidebarRTL ? "rtl" : "ltr"}
          >
            {AllParents &&
              AllParents.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
      <CustomTableBox tableData={tableData} />
    </Box>
  );
};
export default StudentInstallmentsTable;
