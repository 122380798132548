import React, { useEffect, useState } from "react";
import Select from "react-select";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useDispatch, useSelector } from "react-redux";
import { getStudentInfo } from "../../redux/student";
import { useTranslation } from "react-i18next";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import defaultAPI from "../../axiosInstance";
import VerifiedIcon from "@mui/icons-material/Verified";

const BanPage = () => {
  const permission =
    useSelector((state) => state.user.userInfo.data.permissions) || [];
  const { sidebarRTL } = useSidebarContext();
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const [banDescription, setBanDescription] = useState("");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStudentInfo());
  }, [dispatch]);

  const students =
    useSelector((state) => state.student.studentInfo?.data) || [];

  const handleAddBan = async () => {
    if (!selectedStudent || !selectedAction || banDescription.trim() === "") {
      return;
    }

    const info = {
      student_id: selectedStudent.id,
      type: selectedAction.id,
      description: banDescription,
    };

    try {
      await defaultAPI.post("/bans", info);
    } catch (error) {}

    setSelectedStudent(null);
    setSelectedAction(null);
    setBanDescription("");
  };

  const studentOptions =
    students.length > 0 &&
    students.map((student) => ({
      id: student.id,
      name: student.name,
    }));

  const actionOptions = [
    { id: 0, name: t("warning") },
    { id: 1, name: t("ban") },
  ];

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        padding: "20px",
      }}
    >
      <Typography
        variant="h2"
        align={sidebarRTL ? "right" : "left"}
        fontWeight="bold"
      >
        {t("Add Ban Data")}
      </Typography>
      <TextField
        multiline
        rows={4}
        dir={sidebarRTL ? "rtl" : "lrt"}
        placeholder={t("Ban Description")}
        fullWidth
        variant="outlined"
        value={banDescription}
        onChange={(e) => setBanDescription(e.target.value)}
        sx={{
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: colors.blueAccent[700],
            },
          "& .MuiFilledInput-input.MuiInputBase-inputMultiline": {
            textAlign: sidebarRTL ? "end" : "start",
            fontSize: "16px",
          },
        }}
        inputProps={{
          style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
        }}
      />

      <Select
        options={studentOptions}
        placeholder={t("Select Student")}
        value={selectedStudent}
        onChange={setSelectedStudent}
        isSearchable={true}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        styles={{
          option: (base) => ({
            ...base,
            color: "black",
          }),
        }}
      />

      <Select
        options={actionOptions}
        placeholder={t("Select Action")}
        value={selectedAction}
        onChange={setSelectedAction}
        isSearchable={true}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        styles={{
          option: (base) => ({
            ...base,
            color: "black",
          }),
        }}
      />

      <Button
        sx={{
          backgroundColor: colors.blueAccent[700],
          color: colors.grey[100],
          fontSize: "14px",
          fontWeight: "bold",
          padding: "10px 15px",
          display: permission.includes("store-ban") ? "inline-flex" : "none",
        }}
        disabled={
          !selectedStudent || !selectedAction || banDescription.trim() === ""
        }
        onClick={handleAddBan}
        dir={sidebarRTL ? "rtl" : "ltr"}
        endIcon={
          <VerifiedIcon
            dir={sidebarRTL ? "rtl" : "ltr"}
            sx={{ marginRight: sidebarRTL ? ".5rem" : "0" }}
          />
        }
      >
        {t("Add Ban")}
      </Button>
    </Box>
  );
};

export default BanPage;
