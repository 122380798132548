import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import { useDispatch, useSelector } from "react-redux";
import { studentsMultiExpenses } from "../../redux/StudentsExpenses";

const EditMultiSelectMenu = (props) => {
  const dispatch = useDispatch();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const AllGradeExpenses = props.data;
  useEffect(() => {
    dispatch(studentsMultiExpenses(props.studentId));
  }, [dispatch, props.studentId]);

  const DefualtStudentExpenses = useSelector(
    (state) => state.StudentsExpenses.MutliSelectExpenses.data
  );

  useEffect(() => {
    const initialData = props.data.filter(
      (item) =>
        DefualtStudentExpenses &&
        DefualtStudentExpenses.length &&
        DefualtStudentExpenses.includes(item.id)
    );

    setSelectedOptions(initialData);
  }, [DefualtStudentExpenses, props.data]);

  const { t } = useTranslation();
  const { sidebarRTL } = useSidebarContext();

  const handleSelectChange = (selected) => {
    setSelectedOptions(selected);

    // Call the callback function with selected options
    if (props.onSelectedOptions) {
      props.onSelectedOptions(selected);
    }
  };

  return (
    <div style={{ marginTop: "10px", position: "relative" }}>
      <label style={{ fontSize: "18px", fontWeight: "bold" }}>
        {t(`${props.text}`)}
      </label>
      <Select
        options={props.data}
        placeholder={t(`${props.text}`)}
        value={selectedOptions}
        onChange={handleSelectChange}
        isSearchable={true}
        name={props.name}
        isMulti
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        styles={{
          menu: (provided) => ({
            ...provided,
            zIndex: 9999,
            color: "#000",
            direction: sidebarRTL ? "rtl" : "ltr",
          }),
          container: (provided) => ({
            ...provided,
            zIndex: 9999,
            direction: sidebarRTL ? "rtl" : "ltr",
          }),
          menuPortal: (provided) => ({
            ...provided,
            zIndex: 9999,
            direction: sidebarRTL ? "rtl" : "ltr",
          }),
        }}
      />
    </div>
  );
};

export default EditMultiSelectMenu;
