import React, { useState, useEffect } from "react";
import Select from "react-select";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import DefaultButton from "../../components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { addGrade, getClasses, getGrades } from "../../redux/Grades";
import { useTranslation } from "react-i18next";
import { useSidebarContext } from "../global/sidebar/sidebarContext";

const AddGrade = ({ icon, pageSize, show }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClasses());
  }, [dispatch]);

  const data = useSelector((state) => state.grades.classes.data)||[];
  const info = {
    name,
    classes: selectedIds,
    pageSize,
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleAdd = async () => {
    try {
      const response = await dispatch(addGrade(info));
      dispatch(getGrades(info));
      if (response.payload && response.payload.message) {
      } else {
        if (response.payload.name) {
        } else if (response.payload.classes) {
        }
      }
    } catch (error) {
    }
    setOpen(false);
  };

  const handleSelect = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    const selectedIds = selectedOptions.map((option) => option.id);
    setSelectedIds(selectedIds);
  };

  const { sidebarRTL } = useSidebarContext();
  return (
    <div>
      <DefaultButton show={show} handleClick={handleClickOpen} text={t("Add")} />
      <Dialog
        fullWidth={true}
        sx={{ width: { xs: "100%", md: "50%" }, margin: "auto", padding: "0" }}
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle sx={{ fontWeight: "bold", fontSize: "22px" }} align={sidebarRTL ? "right" : "left"}>{t("Add")}</DialogTitle>
        <DialogContent dir={sidebarRTL?"rtl":"ltr"} sx={{ height: "400px" }}>
          <div style={{ marginBottom: "10px" }}>
          <lable style={{fontSize:"18px",fontWeight: "Bold"}}>{t("Name")}</lable>
            <TextField
              autoFocus
              margin="dense"
              placeholder={t("Name")}
              type="text"
              fullWidth
              variant="outlined"
              value={name}
              dir={sidebarRTL ? "rtl" : "ltr"}
              onChange={(e) => setName(e.target.value)}
              inputProps={{
                style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
              }}
            />
          </div>
          <div
            style={{ marginTop: "10px", position: "relative" }}
          >
            <lable style={{fontSize:"18px",fontWeight: "Bold"}}>{t("Select Classes")}</lable>
            <Select
              options={data.length>0 && data}
              placeholder={t("Select Classes")}
              value={selectedOptions}
              onChange={handleSelect}
              isSearchable={true}
              isMulti
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  color:"#000",
                  direction: sidebarRTL ? "rtl" : "ltr"
                }),
                container: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  direction: sidebarRTL ? "rtl" : "ltr"
                }),
                menuPortal: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  direction: sidebarRTL ? "rtl" : "ltr"
                }),
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
            onClick={() => setOpen(false)}
          >
            {t("Cancel")}
          </Button>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 15px",
            }}
            disabled={!name || selectedIds.length === 0}
            endIcon={icon}
            onClick={handleAdd}
          >
            {t("Add")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddGrade;
