import React, { useState } from "react";
import Select from "react-select";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useDispatch, useSelector } from "react-redux";
import defaultAPI from "../../axiosInstance";
import { editSubject, getSubjects } from "../../redux/Subjects";
import { useTranslation } from "react-i18next";
import { useSidebarContext } from "../global/sidebar/sidebarContext";

const EditSubject = ({ id, pageSize, icon, sx }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const data = useSelector((state) => state.subjects.grades.data);
  const dispatch = useDispatch();
  const { sidebarRTL } = useSidebarContext();

  const handleClickOpen = async () => {
    try {
      const apiUrl = `/subjects/${id}`;
      const res = await defaultAPI.get(apiUrl);
      setName(res.data.data.name);

      const newSelectedOptions = data.filter((option) =>
        res.data.data.grades.includes(option.id)
      );
      setSelectedOptions(newSelectedOptions);
      setOpen(true);
    } catch (error) {
      console.error("Error fetching subject info:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = async () => {
    setOpen(false);
    const grades = selectedOptions.map((option) => option.id);
    const info = {
      id,
      name,
      grades,
      pageSize,
    };

    try {
      await dispatch(editSubject(info))
        .then((res) => {
          //toast.success(res.payload.message);
          dispatch(getSubjects(info));
        })
        .catch((error) => {
          //toast.error("Error editing subject information");
        });
    } catch (error) {
      //toast.error("Error editing subject information");
    }
  };

  const buttonStyles = {
    backgroundColor: colors.blueAccent[700],
    color: colors.grey[100],
    fontSize: "14px",
    fontWeight: "bold",
    padding: "10px 20px",
  };

  return (
    <div>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        sx={sx}
        endIcon={icon}
      >
        {t("Edit")}
      </Button>
      <Dialog
        fullWidth={true}
        sx={{
          width: { xs: "100%", md: "50%" },
          margin: "auto",
          padding: "0",
        }}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle sx={{ fontWeight: "bold", fontSize: "22px" }} align={sidebarRTL ? "right" : "left"}>{t("Edit")}</DialogTitle>
        <DialogContent sx={{ height: "400px" }}>
          {/* <DialogContentText>Edit Subject</DialogContentText> */}
          <div style={{ marginBottom: "10px" }} dir={sidebarRTL?"rtl":"ltr"}>
          <lable style={{fontSize:"18px",fontWeight: "Bold"}}>{t("Name")}</lable>
            <TextField
              autoFocus
              margin="dense"
              placeholder={t("Name")}
              type="text"
              fullWidth
              variant="outlined"
              dir={sidebarRTL ? "rtl" : "ltr"}
              value={name}
              onChange={(e) => setName(e.target.value)}
              inputProps={{
                style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
              }}
            />
          </div>
          <div
            style={{ marginTop: "10px", position: "relative", color: "#000" }}
            dir={sidebarRTL?"rtl":"ltr"}
          >
            <lable style={{fontSize:"18px",fontWeight: "Bold",color:colors.grey[100]}}>{t("Select Grades")}</lable>
            <Select
              options={data}
              placeholder={t("Select Grades")}
              value={selectedOptions}
              onChange={(selected) => setSelectedOptions(selected)}
              isMulti
              isSearchable
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  direction: sidebarRTL ? "rtl" : "ltr"
                }),
                container: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  direction: sidebarRTL ? "rtl" : "ltr"
                }),
                menuPortal: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  direction: sidebarRTL ? "rtl" : "ltr"
                }),
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button sx={buttonStyles} onClick={handleClose}>
            {t("Cancel")}
          </Button>
          <Button
            sx={buttonStyles}
            disabled={!name || selectedOptions.length === 0}
            endIcon={icon}
            onClick={handleEdit}
          >
            {t("Edit")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditSubject;
