import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import { tokens } from "../../theme";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { editPlane, studyPlaneData } from "../../redux/studypalne";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { resetOnePlane, showOnePlaneData } from "../../redux/showOnePlane";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useSidebarContext } from "../global/sidebar/sidebarContext";

const EditPlaneForm = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const colors = tokens(theme.palette.mode);
  const { sidebarRTL } = useSidebarContext();
  const onePlaneData = useSelector((state) => state.showOnePlane.onePlane.data);
  const [initialValues, setInitialValuesSet] = useState(false);

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const validationSchema = yup.object().shape({
    name: yup.string().required("Required"),
    created_at: yup.string().required("Required"),
    type: yup.number().required("Required"), // Add validation for the type field
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      created_at: "",
      type: "", // Initialize type field
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = async (values) => {
    try {
      const formattedDate = values.created_at.format("YYYY-MM-DD");
      values.created_at = formattedDate;

      const ID = props.ID;
      const info = {
        ID,
        values,
      };
      const dataInfo = {
        pageSize: props.pageSize,
      };
      await dispatch(editPlane(info)).then(res => res.payload.code === 200 && handleClose()).then(() => dispatch(studyPlaneData(dataInfo)));
      handleClose();
    } catch (error) {
      console.error("Error during form submission:", error);
    }
  };

  const handleClickOpen = async () => {
    setOpen(true);
    await dispatch(showOnePlaneData(props.ID));
  };

  const handleClose = () => {
    setOpen(false);
    setInitialValuesSet(false);
    formik.resetForm();
    dispatch(resetOnePlane());
  };

  useEffect(() => {
    if (open && onePlaneData && !initialValues) {
      formik.setValues({
        name: onePlaneData.name,
        created_at: dayjs(onePlaneData.created_at),
        type: onePlaneData.type, // Set initial type value
      });
      setInitialValuesSet(true);
    }
  }, [open, onePlaneData, initialValues, formik]);

  return (
    <Box m="20px">
      <Button
        variant="contained"
        sx={props.sx}
        endIcon={props.icon}
        onClick={handleClickOpen}
      >
        {t("Edit")}
      </Button>
      <Dialog dir={sidebarRTL ? "rtl" : "ltr"} open={open} onClose={handleClose}>
        <DialogTitle sx={{ fontWeight: "bold", fontSize: "22px" }}>{t("Edit")}</DialogTitle>
        <DialogContent dir={sidebarRTL?"rtl":"ltr"}>
          <form onSubmit={formik.handleSubmit}>
            <Box
              display="grid"
              gap="20px"
              sx={{
                "& > div": {
                  gridColumn: isNonMobile ? undefined : "span 4",
                },
              }}
            >
              <Box display="flex" flexDirection="column" sx={{gridColumn: "span 2"}}>
              <lable style={{fontSize:"18px",fontWeight: "Bold"}}>{t("Name")}</lable>
              <TextField
                fullWidth={true}
                variant="outlined"
                type="text"
                placeholder={t("Name")}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                name="name"
                dir={sidebarRTL ? "right" : "left"}
                error={!!formik.touched.name && !!formik.errors.name}
                helperText={formik.touched.name && formik.errors.name}
                inputProps={{
                  style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
                }}
                />
              </Box>
              <Box display="flex" flexDirection="column" sx={{gridColumn: "span 2"}}>
              <lable style={{fontSize:"18px",fontWeight: "Bold"}}>{t("StartDate")}</lable>
              <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth={true}>
                <DatePicker
                  format="YYYY/MM/DD"
                  value={formik.values.created_at}
                  onBlur={() => formik.setFieldTouched("created_at", true)}
                  onChange={(date) => formik.setFieldValue("created_at", date)}
                  name="created_at"
                  error={
                    !!formik.touched.created_at && !!formik.errors.created_at
                  }
                  helperText={
                    formik.touched.created_at && formik.errors.created_at
                  }
                  renderInput={(props) => (
                    <TextField {...props} variant="outlined" fullWidth inputProps={{
                      style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
                    }} />
                  )}
                />
                </LocalizationProvider>
                </Box>
              <FormControl fullWidth sx={{gridColumn:"span 2"}} variant="outlined" error={!!formik.touched.type && !!formik.errors.type}>
                <lable>{t("Type")}</lable>
                <Select
                fullWidth
                  value={formik.values.type}
                  onBlur={() => formik.setFieldTouched("type", true)}
                  onChange={formik.handleChange}
                  name="type"
                  dir={sidebarRTL?"rtl":"ltr"}
                  sx={{gridColumn:"span 2"}}
                >
                  <MenuItem value="" disabled>
                    {t("Select a type")}
                  </MenuItem>
                  <MenuItem dir={sidebarRTL?"rtl":"ltr"} value={1}>{t("Holiday")}</MenuItem>
                  <MenuItem dir={sidebarRTL?"rtl":"ltr"} value={2}>{t("Occasion")}</MenuItem>
                </Select>
                {formik.touched.type && formik.errors.type && (
                  <div style={{ color: "red" }}>{formik.errors.type}</div>
                )}
              </FormControl>
            </Box>
            <Box display="flex" mt="20px">
              <Button
                type="submit"
                sx={{
                  backgroundColor: colors.blueAccent[700],
                  color: colors.grey[100],
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 20px",
                  flexDirection: sidebarRTL ? "row-reverse" : "row"
                }}
                variant="outlined"
                endIcon={<AddIcon />}
              >
                {t("Edit")}
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default EditPlaneForm;
