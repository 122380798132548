import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import { tokens } from "../../theme";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux"; // Import the useDispatch hook
import {
  ShowParent,
  editParent,
  parentData,
  resetShowParent,
} from "../../redux/parents";
import { useTranslation } from "react-i18next";
import { IconButton, InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useSidebarContext } from "../global/sidebar/sidebarContext";

const EditParent = (props) => {
  const { t } = useTranslation();
  const { sidebarRTL } = useSidebarContext();

  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch(); // Get the dispatch function
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const loading = useSelector((state) => state.parentData.loading);
  const initialValues = {
    type: "parent",
    name: props.name,
    username: props.userName,
    phone: props.phone,
    password: "",
    password_confirmation: "",
  };
  const checkoutSchema = yup.object().shape({
    name: yup.string().required("Required"),
    username: yup.string().required("Required"),
    phone: yup.number().required("Required"),
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters long")
      .matches(
        /^(?=.*[A-Z][a-z])/,
        "Password must contain at least one uppercase letter and least one lowercase letter"
      ),
    password_confirmation: yup
      .string()
      .min(8)
      .oneOf([yup.ref("password")], "Passwords must match"),
  });
  const [open, setOpen] = useState(false);

  const handleClickOpen = async () => {
    await dispatch(ShowParent(props.ID));
    setOpen(true);
  };
  const handleClose = async () => {
    setOpen(false);
    await dispatch(resetShowParent());
  };
  const handleFormSubmit = async (values) => {
    const info = {
      values: values,
      id: props.ID,
      getInfo: {
        pageSize: props.pageSize,
      },
    };
    await dispatch(editParent(info)).then((res) =>
      res.payload.code === 200
        ? dispatch(parentData(info.getInfo)).then(handleClose)
        : null
    );
  };
  return (
    <Box m="20px">
      <Button
        variant="contained"
        sx={props.sx}
        endIcon={props.icon}
        onClick={handleClickOpen}
      >
        {t("Edit")}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          sx={{ fontWeight: "bold", fontSize: "22px" }}
          align={sidebarRTL ? "right" : "left"}
        >
          {t("Edit")}
        </DialogTitle>
        <DialogContent dir={sidebarRTL?"rtl":"ltr"}>
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                >
                  <Box display="flex" flexDirection="column" sx={{gridColumn: "span 2"}}>
                  <lable style={{fontSize:"18px",fontWeight: "Bold"}}>{t("Name")}</lable>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    placeholder={t("Name")}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    name="name"
                    error={!!touched.name && !!errors.name}
                    helperText={touched.name && errors.name}
                    sx={{ gridColumn: "span 2" }}
                    dir={sidebarRTL ? "rtl" : "ltr"}
                    inputProps={{
                      style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
                    }}
                    />
                  </Box>
                  <Box display="flex" flexDirection="column" sx={{gridColumn: "span 2"}}>
                  <lable style={{fontSize:"18px",fontWeight: "Bold"}}>{t("Username")}</lable>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    placeholder={t("Username")}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.username}
                    name="username"
                    error={!!touched.username && !!errors.username}
                    helperText={touched.username && errors.username}
                    sx={{ gridColumn: "span 2" }}
                    dir={sidebarRTL ? "rtl" : "ltr"}
                    inputProps={{
                      style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
                    }}
                    />
                  </Box>
                  <Box display="flex" flexDirection="column" sx={{gridColumn: "span 2"}}>
                  <lable style={{fontSize:"18px",fontWeight: "Bold"}}>{t("Password")}</lable>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    placeholder={t("Password")}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    name="password"
                    error={!!touched.password && !!errors.password}
                    helperText={touched.password && errors.password}
                    sx={{ gridColumn: "span 2" }}
                    dir={sidebarRTL ? "rtl" : "ltr"}
                    InputProps={{
                      style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    />
                  </Box>
                  <Box display="flex" flexDirection="column" sx={{gridColumn: "span 2"}}>
                  <lable style={{fontSize:"18px",fontWeight: "Bold"}}>{t("Password Confirmation")}</lable>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    placeholder={t("Password Confirmation")}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password_confirmation}
                    name="password_confirmation"
                    error={
                      !!touched.password_confirmation &&
                      !!errors.password_confirmation
                    }
                    helperText={
                      touched.password_confirmation &&
                      errors.password_confirmation
                    }
                    sx={{ gridColumn: "span 2" }}
                    dir={sidebarRTL ? "rtl" : "ltr"}
                    InputProps={{
                      style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    />
                  </Box>
                  <Box display="flex" flexDirection="column" sx={{gridColumn: "span 2"}}>
                  <lable style={{fontSize:"18px",fontWeight: "Bold"}}>{t("Phone")}</lable>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    placeholder={t("Phone")}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phone}
                    name="phone"
                    error={!!touched.phone && !!errors.phone}
                    helperText={touched.phone && errors.phone}
                    sx={{ gridColumn: "span 2" }}
                    dir={sidebarRTL ? "rtl" : "ltr"}
                    inputProps={{
                      style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
                    }}
                    />
                  </Box>
                </Box>
                <Box display="flex" mt="20px">
                  <Button
                    type="submit"
                    sx={{
                      backgroundColor: colors.primary[700],
                      color: colors.grey[100],
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px 20px",
                    }}
                    disabled={loading ? true : false}
                    variant="outlined"
                  >
                    {loading ? "wait..." : t("Edit")}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default EditParent;
