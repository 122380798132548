import {Box, Button, Dialog, DialogContent, DialogTitle, TextField, useMediaQuery, useTheme,} from "@mui/material";
import {useFormik} from "formik";
import * as yup from "yup";
import {tokens} from "../../theme";
import AddIcon from "@mui/icons-material/Add";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import DropDownGradesSubjects from "./DropDownAdd";
import {useEffect, useState} from "react";
import {useSidebarContext} from "../global/sidebar/sidebarContext";
import {selectExpenses} from "../../redux/gradeExpenses";
// import dayjs from 'dayjs';
import {DemoContainer} from '@mui/x-date-pickers/internals/demo';
import {MobileTimePicker} from '@mui/x-date-pickers/MobileTimePicker';
import {addExamSchedule, getExamSchedule} from "../../redux/ExamSchedule";

const AddExamSchedule = (props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const {sidebarRTL} = useSidebarContext();
    const loading = useSelector((state) => state.loading);

    const checkoutSchema = yup.object().shape({
        exam_date: yup.date().required("Required"),
        from: yup.date().required("Required"),
        to: yup.date().required("Required"),
    });

    const formik = useFormik({
        initialValues: {
            exam_date: "",
            from: "",
            to: "",
        },
        validationSchema: checkoutSchema,
        onSubmit: (values) => {
            handleFormSubmit(values);
        },
    });

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = async () => {
        const info = {
            gradeId: props.gradeExpensesId,
            pageSize: props.pageSize,
            typeId: props.typeId
        };
        setOpen(false);
        await dispatch(getExamSchedule(info))
        formik.resetForm()
    };
    useEffect(() => {
        dispatch(selectExpenses())
    }, [open === true])
    const handleFormSubmit = async (values) => {
        const formValues = {
            ...values,
            exam_date: values.exam_date.format("YYYY-MM-DD"),
            from: values.from.format("HH:mm"),
            to: values.to.format("HH:mm")
        }
        await dispatch(addExamSchedule(formValues))
            .then((res) =>
                res.payload.code === 201 ? handleClose() : setOpen(true)
            );
    };

    const setFormikFieldValue = (field, value) => {
        formik.setFieldValue(field, value);
    };
    return (
        <Box m="20px">
            <Button
                sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px",
                    display: props.show ? "inline-flex" : "none"
                }}
                variant="outlined"
                onClick={handleClickOpen}
                endIcon={<AddIcon/>}
            >
                {t("Add")}
            </Button>
            <Dialog
                sx={{width: {xs: "100%", md: "50%"}, margin: "auto", padding: "0"}}
                open={open}
                onClose={handleClose}
            >
                <DialogContent dir={sidebarRTL ? "rtl" : "ltr"}>
                    <DialogTitle sx={{fontWeight: "bold", fontSize: "22px"}}
                                 align={sidebarRTL ? "right" : "left"}>{t("Add", " ", "prices")}</DialogTitle>
                    <form onSubmit={formik.handleSubmit} dir={sidebarRTL ? "ltr" : "rtl"}>
                        <DropDownGradesSubjects setFormikValues={setFormikFieldValue}/>
                        <Box
                            dir={sidebarRTL ? "rtl" : "ltr"}
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": {gridColumn: isNonMobile ? undefined : "span 4"},
                            }}
                        >
                            <lable style={{fontSize: "18px", fontWeight: "Bold"}}>{t("StartDate")}</lable>
                            <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth={true}>
                                <DatePicker
                                    dir={sidebarRTL ? "rtl" : "ltr"}
                                    sx={{direction: sidebarRTL ? "rtl" : "ltr", gridColumn: "span 4"}}
                                    placeholder={t("Date Picker")}
                                    format="YYYY/MM/DD"
                                    value={formik.values.exam_date}
                                    onBlur={() => formik.setFieldTouched("exam_date", true)}
                                    onChange={(date) => formik.setFieldValue("exam_date", date)}
                                    name="exam_date"
                                    error={
                                        !!formik.touched.exam_date && !!formik.errors.exam_date
                                    }
                                    helperText={
                                        formik.touched.exam_date && formik.errors.exam_date
                                    }
                                    renderInput={(props) => (
                                        <TextField {...props} variant="outlined" fullWidth/>
                                    )}
                                />
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer

                                    sx={{direction: sidebarRTL ? "rtl" : "ltr", gridColumn: "span 4"}}

                                    components={[
                                        'TimePicker',
                                        'MobileTimePicker',
                                        'StaticTimePicker',
                                    ]}
                                >
                                    <lable style={{fontSize: "18px", fontWeight: "Bold"}}>{t("StartTime")}</lable>

                                    <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth={true}>
                                        <MobileTimePicker
                                            dir={sidebarRTL ? "rtl" : "ltr"}
                                            fullWidth
                                            ampm={false}
                                            sx={{direction: sidebarRTL ? "rtl" : "ltr", gridColumn: "span 4"}}
                                            placeholder={t("Start time")}
                                            value={formik.values.from}
                                            onBlur={() => formik.setFieldTouched("from", true)}
                                            onChange={(from) => formik.setFieldValue("from", from)}
                                            name="from"
                                            error={
                                                !!formik.touched.from && !!formik.errors.from
                                            }
                                            helperText={
                                                formik.touched.from && formik.errors.from
                                            }
                                            renderInput={(props) => (
                                                <TextField {...props} variant="outlined" fullWidth/>
                                            )}
                                        />
                                    </LocalizationProvider>
                                    <lable style={{fontSize: "18px", fontWeight: "Bold"}}>{t("EndDate")}</lable>

                                    <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth={true}>
                                        <MobileTimePicker
                                            dir={sidebarRTL ? "rtl" : "ltr"}
                                            fullWidth
                                            ampm={false}
                                            sx={{direction: sidebarRTL ? "rtl" : "ltr", gridColumn: "span 4"}}
                                            placeholder={t("Start time")}
                                            value={formik.values.to}
                                            onBlur={() => formik.setFieldTouched("to", true)}
                                            onChange={(to) => formik.setFieldValue("to", to)}
                                            name="to"
                                            error={
                                                !!formik.touched.to && !!formik.errors.to
                                            }
                                            helperText={
                                                formik.touched.to && formik.errors.to
                                            }
                                            renderInput={(props) => (
                                                <TextField {...props} variant="outlined" fullWidth/>
                                            )}
                                        />
                                    </LocalizationProvider>


                                </DemoContainer>
                            </LocalizationProvider>
                        </Box>
                        <Box display="flex" justifyContent="end" mt="20px">
                            <Button
                                type="submit"
                                sx={{
                                    backgroundColor: colors.blueAccent[700],
                                    color: colors.grey[100],
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    padding: "10px 20px",
                                }}
                                disabled={loading ? true : false}
                                variant="outlined"
                                onClick={handleClickOpen}
                            >
                                {loading ? t("wait") : t("Add")}
                            </Button>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default AddExamSchedule;
