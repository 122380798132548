import React, { useEffect, useState } from "react";
import Select from "react-select";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useDispatch, useSelector } from "react-redux";
import student, { getStudentInfo } from "../../redux/student";
import { useTranslation } from "react-i18next";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import defaultAPI from "../../axiosInstance";
import VerifiedIcon from '@mui/icons-material/Verified';


const AddAppreciations = () => {
  const permission = useSelector(state => state.user.userInfo.data.permissions) || [];
  const { sidebarRTL } = useSidebarContext();
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [appreciationsDesc, setAppreciationsDesc] = useState("");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const students =
    useSelector((state) => state.student.studentInfo?.data) || [];
  
  useEffect(() => {
    dispatch(getStudentInfo());
  }, [dispatch||students==[]]);

  

  const handleAddGuardians = async () => {
    if (!selectedStudent || appreciationsDesc.trim() === "") {
      return;
    }

    const info = {
      student_id: selectedStudent.id,
      description: appreciationsDesc,
    };

    try {
      await defaultAPI.post("/appreciations", info);
    } catch (error) { }
    setSelectedStudent(null);
    setAppreciationsDesc("");
  };

  const studentOptions = students.length > 0 && students.map((student) => ({
    id: student.id,
    name: student.name,
  }));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        padding: "20px",
      }}
      die={sidebarRTL?"rtl":"ltr"}

    >
      <Typography
        variant="h2"
        align={sidebarRTL ? "right" : "left"}
        fontWeight="bold"
      >
        {t("Add Appreciations Data")}
      </Typography>
      <TextField
        dir={sidebarRTL?"rtl":"ltr"}
        multiline
        rows={4}
        placeholder={t("Appreciations Description")}
        fullWidth
        variant="outlined"
        value={appreciationsDesc}
        onChange={(e) => setAppreciationsDesc(e.target.value)}
        sx={{
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            borderColor: colors.blueAccent[700],
          },
          "& .MuiFilledInput-input.MuiInputBase-inputMultiline": {
            textAlign: sidebarRTL ? "end" : "start",
            fontSize: "16px",
          },
        }}
        inputProps={{
          style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
        }}
      />

      <Select
        options={studentOptions}
        placeholder={t("Select Student")}
        value={selectedStudent}
        onChange={setSelectedStudent}
        isSearchable={true}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        styles={{
          option: (base) => ({
            ...base,
            color: "black",
            fontSize:"16px",
          }),
        }}
      />

      <Button
        sx={{
          backgroundColor: colors.blueAccent[700],
          color: colors.grey[100],
          fontSize: "18px",
          fontWeight: "bold",
          padding: "10px 15px",
          display: permission.includes('store-appreciation') ? "inline-flex" : "none"
        }}
        disabled={!selectedStudent || appreciationsDesc.trim() === ""}
        onClick={handleAddGuardians}
        endIcon={<VerifiedIcon dir={sidebarRTL ? "rtl" : "ltr"} sx={{ marginRight: sidebarRTL ? ".5rem" : "0" }} />}
        dir={sidebarRTL ? "rtl" : "ltr"}
      >
        {t("Add Appreciations")}
      </Button>
    </Box>
  );
};

export default AddAppreciations;
