import React, { useEffect, useState } from "react";
import { Box, ButtonGroup, LinearProgress, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Pagenation from "./pagenation";
import DeleteExam from "./DeleteExam";
import AddExam from "./AddExam";
import { useTranslation } from "react-i18next";
import { fetchExamsData, SearchExamsData } from "../../redux/Exams";
import EditExam from "./editExam";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import CustomSearch from "../../components/customSearch/customSearch";
const ExamsTable = () => {
  const permission =
    useSelector((state) => state.user.userInfo.data.permissions) || [];
  const { sidebarRTL } = useSidebarContext();
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(10);
  const { t } = useTranslation();
  const ExamsData = useSelector((state) => state.exams.examsData.data);
  const loading = useSelector((state) => state.exams.loading);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const info = {
    pageSize,
  };

  const FullmarkWidth =
    permission.includes("update-exam") && permission.includes("delete-exam")
      ? 150
      : 450;
  const nameWidth =
    permission.includes("update-exam") && permission.includes("delete-exam")
      ? 350
      : 450;
  const idWidth =
    permission.includes("update-exam") && permission.includes("delete-exam")
      ? 100
      : 320;

  const columns = [
    { field: "id", headerName: t("ID"), width: idWidth },
    {
      field: "name",
      headerName: t("Name"),
      width: nameWidth,
      cellClassName: "name-column--cell",
    },
    {
      field: "defaultFullMark",
      headerName: t("Full Mark"),
      width: FullmarkWidth,
    },
    ...(permission.includes("update-exam") && permission.includes("delete-exam")
      ? [
          {
            field: "actions",
            headerName: t("Actions"),
            width: 400,
            renderCell: (params) => (
              <ButtonGroup
                sx={{
                  display: "flex",
                  flexDirection: sidebarRTL
                    ? "row-reverse !important"
                    : "row !important",
                  alignItems: "center",
                }}
              >
                <EditExam
                  sx={{
                    backGround: `${colors.grey[600]}`,
                    display: permission.includes("update-exam")
                      ? "inline-flex"
                      : "none",
                  }}
                  name={params.row.name}
                  defaultFullMark={params.row.defaultFullMark}
                  ID={params.id}
                  icon={<EditIcon />}
                  pageSize={pageSize}
                />
                <DeleteExam
                  pageSize={pageSize}
                  sx={{
                    background: `${colors.redAccent[600]}`,
                    display: permission.includes("update-exam")
                      ? "inline-flex"
                      : "none",
                  }}
                  icon={<DeleteIcon />}
                  id={params.id}
                />
              </ButtonGroup>
            ),
          },
        ]
      : []),
  ];

  useEffect(() => {
    dispatch(fetchExamsData(info)); // Initial data fetching
  }, [dispatch, pageSize]);

  return (
    <Box m="20px">
      <Box
        display="flex"
        flexDirection={sidebarRTL ? "row-reverse" : "row"}
        justifyContent="space-between"
        alignItems="center"
      >
        <Header title={t("Exams")} />
        <AddExam show={permission.includes("store-exam")} pageSize={pageSize} />
      </Box>
      <Box
        m="8px 0 0 0"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
            fontWeight: "bold",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            fontWeight: "bold",
            fontSize: "20px",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiChackbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiButtonGroup-root.MuiButtonGroup-outlined.css-iajp3t-MuiButtonGroup-root":
            {
              display: "flex",
              alignItems: "center",
              flexDirection: sidebarRTL ? "row-reverse" : "row",
              gap: sidebarRTL ? "35px" : "0px",
            },
          "& .MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
            justifyContent: sidebarRTL
              ? "flex-end !important"
              : "flex-start !important",
          },
          "& .MuiDataGrid-columnHeaderDraggableContainer": {
            flexDirection: sidebarRTL
              ? "row-reverse !important"
              : "row !important",
          },
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            justifyContent: sidebarRTL ? "end !important" : "start !important",
            flexDirection: sidebarRTL
              ? "row-reverse !important"
              : "row !important",
          },
        }}
      >
        <CustomSearch

          action={SearchExamsData}
          pageSize={pageSize}
        />
        <DataGrid
          sx={{
            fontWeight: "bold",
            fontSize: "14px",
          }}
          rows={ExamsData && ExamsData.length ? ExamsData : []}
          disableSelectionOnClick={true}

          columns={sidebarRTL ? columns.reverse() : columns}
          loading={loading && true}
          autoHeight
          pagination
          componentsProps={{
            pagination: {
              labelRowsPerPage: t("rowsPerPageText"),
              dir: sidebarRTL ? "rtl" : "ltr",
            },
          }}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 15]}
        />
        <Pagenation pageSize={pageSize} />
      </Box>
    </Box>
  );
};
export default ExamsTable;
