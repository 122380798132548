import React, { useEffect, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { dashboardClasses, dashboardGrades } from "../../redux/dashboard";
import { useTranslation } from "react-i18next";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const DropDown = ({ selectedClass, setSelectedClass }) => {
  const permission = useSelector(state => state.user.userInfo.data.permissions) || [];
  const [gradeAnchorEl, setGradeAnchorEl] = useState(null);
  const [classAnchorEl, setClassAnchorEl] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [className, setClassName] = useState(null);
  const loadingGrades = useSelector((state) => state.dashboard.loading);
  const loadingClasses = useSelector((state) => state.dashboard.loading);
  const grades = useSelector((state) => state.dashboard.grades);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(dashboardGrades());
  }, []);

  useEffect(() => {
    if (grades.length > 0) {
      setSelectedGrade(grades[0]);
      dispatch(dashboardClasses({ id: grades[0].id, }));
    }
  }, [dispatch, grades]);

  const handleClickGrade = (event) => {
    setGradeAnchorEl(event.currentTarget);
  };

  const handleCloseGrade = () => {
    setGradeAnchorEl(null);
  };

  const handleClickClass = (event) => {
    setClassAnchorEl(event.currentTarget);
  };

  const handleCloseClass = () => {
    setClassAnchorEl(null);
  };

  const handleGradeSelect = (grade) => () => {
    setSelectedGrade(grade);
    setGradeAnchorEl(null);
    const info = {
      id: grade.id,
    };
    dispatch(dashboardClasses(info));
  };

  const classes = useSelector((state) => state.dashboard.classes);

  useEffect(() => {
    if (classes.length > 0) {
      setClassName(classes[0].name)
    }
  }, [classes]);
  const handleClassSelect = (class_info) => {
    setSelectedClass(class_info.target.value);
    setClassAnchorEl(null);
  };

  return (
    <div style={{
      display: permission.includes("show-student") ? "block" : "none",
    }}>
      <Button
        id="demo-customized-button"
        aria-controls={gradeAnchorEl ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={gradeAnchorEl ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClickGrade}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{ mr: 2 }}
      >
        {selectedGrade ? selectedGrade.name : t("Grade")}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={gradeAnchorEl}
        open={Boolean(gradeAnchorEl)}
        onClose={handleCloseGrade}
      >
        {loadingGrades ? (
          <MenuItem disabled>{t("Loading")}</MenuItem>
        ) : grades && grades.length > 0 ? (
          grades.map((grade) => (
            <Box key={grade.id}>
              <MenuItem onClick={handleGradeSelect(grade)} disableRipple>
                {grade.name}
              </MenuItem>
              <Divider sx={{ my: 0.5 }} />
            </Box>
          ))
        ) : (
          <MenuItem disabled>{t("No grades available")}</MenuItem>
        )}
      </StyledMenu>

      {selectedGrade && (
        <>
          <Button
            id="class-menu-button"
            aria-controls={classAnchorEl ? "class-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={classAnchorEl ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={handleClickClass}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {className === null ? t("Class") : className}
          </Button>
          <StyledMenu
            id="class-menu"
            MenuListProps={{
              "aria-labelledby": "class-menu-button",
            }}
            anchorEl={classAnchorEl}
            open={Boolean(classAnchorEl)}
            onClose={handleCloseClass}
          >
            {loadingClasses ? (
              <MenuItem disabled>{t("Loading")}</MenuItem>
            ) : classes && classes.length > 0 ? (
              classes.map((classItem) => (
                <Box key={classItem.id}>
                  <MenuItem
                    onClick={(e) => {
                      setClassName(classItem.name);
                      handleClassSelect(e);
                    }}
                    value={classItem.id}
                    disableRipple
                  >
                    {classItem.name}
                  </MenuItem>
                  <Divider sx={{ my: 0.5 }} />
                </Box>
              ))
            ) : (
              <MenuItem disabled>{t("No Class available")}</MenuItem>
            )}
          </StyledMenu>
        </>
      )}
    </div>
  );
};

export default DropDown;
