import React, { useState, useEffect } from "react";
import {
  Box,
  ButtonGroup,
  FormControl,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import AddIcon from "@mui/icons-material/Add";
import Header from "../../components/Header";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { absenceSearch, fetchAbsenceDataByPage, getAbsence, getClassesMenu } from "../../redux/absence";
import AddAbsence from "./addAbsence";
import DeleteAbsence from "./DeleteAbsence";
import EditAbsence from "./EditAbsence";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import CustomPagination from "../../components/CustomPagenation/CustomPagenation";
import CustomTableBox from "../../components/customTableBox/CustomTableBox";
import CustomToolTip from "../../components/CustomToolTip/customToolTip";

const AbsenceTable = () => {
  const firstPage = useSelector((state) => state.absence.absenceLinks.first);
  const nextPage = useSelector((state) => state.absence.absenceLinks.next);
  const lastPage = useSelector((state) => state.absence.absenceLinks.last);
  const prevPage = useSelector((state) => state.absence.absenceLinks.prev);
  const currentPage = useSelector((state) => state.absence.currentPage);
  const permission = useSelector(state => state.user.userInfo.data.permissions) || [];
  const { sidebarRTL } = useSidebarContext();
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [pageSize, setPageSize] = useState(10);
  const [selectedClassId, setSelectedClassId] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getClassesMenu());
  }, [dispatch]);

  const absenceData =
    useSelector((state) => state?.absence?.absence?.data) || [];
  const classesData =
    useSelector((state) => state?.absence?.classes?.data) || [];
  const loading = useSelector((state) => state?.classes?.loading);

  useEffect(() => {
    if (classesData.length > 0 && selectedClassId === "") {
      setSelectedClassId(classesData[0].id);
    } else {
      dispatch(getAbsence({ id: selectedClassId, pageSize: pageSize }));
    }
  }, [dispatch, selectedClassId, classesData, pageSize]);

  const handleSelectChange = (event) => {
    const selectedId = event.target.value;
    setSelectedClassId(selectedId);
  };

  const nameWidth = permission.includes('update-absence') && permission.includes('delete-absence') ? 200 : 700;
  const idWidth = permission.includes('update-absence') && permission.includes('delete-absence') ? 250 : 480;

  const columns = [
    { field: "id", headerName: t("ID"), width: idWidth },
    {
      field: "name",
      headerName: `${t("Name")}`,
      width: nameWidth,
      cellClassName: "name-column--cell",
    },
    {
      field: "type",
      headerName: `${t("Type")}`,
      width: 200,
      cellClassName: "name-column--cell",
      // renderCell: (params) => (
      //   <CustomToolTip text={t(`${params.row.type}`)}/>
      // )
    },
    {
      field: "createdAt",
      headerName: `${t("Created")}`,
      width: 150,
      cellClassName: "name-column--cell",
    },
    ...(permission.includes('update-absence') && permission.includes('delete-absence') ? [
      {
        field: "actions",
        headerName: `${t("Actions")}`,
        width: 400,
        renderCell: (params) => (
          <ButtonGroup sx={{ display: "flex", flexDirection: sidebarRTL ? "row-reverse !important" : "row !important", alignItems: "center" }}>
            <EditAbsence
              sx={{
                background: `${colors.primary[600]}`, m: 4,
                display: permission.includes('update-absence') ? "inline-flex" : "none"
              }}
              id={params.id}
              icon={<EditIcon />}
              selectedClassId={selectedClassId}
              pageSize={pageSize}
            />
            <DeleteAbsence
              sx={{
                background: `${colors.redAccent[600]}`,
                display: permission.includes('delete-absence') ? "inline-flex" : "none"
              }}
              id={params.id}
              selectedId={selectedClassId}
              icon={<DeleteForeverIcon />}
              pageSize={pageSize}
            />
          </ButtonGroup>
        ),
      }] : [])
  ];
  const tableData = {
          rows:absenceData.length>0 && absenceData,
          loading:loading === true,
          pageSize:pageSize,
          onPageSizeChange:(newPageSize) => setPageSize(newPageSize),
          columns:columns
  }
  // console.log(classesData);
  return (
    <Box m="20px">
      <Box display="flex" flexDirection={sidebarRTL ? "row-reverse" : "row"} justifyContent="space-between" alignItems="center">
        <Header style={permission.includes('all-absence')} title={t("All_Absence")} />
      </Box>
      <Box display="flex" justifyContent="space-between" flexDirection={sidebarRTL ? "row-reverse" : "row"}>
        <AddAbsence show={permission.includes('store-absence')}
          pageSize={pageSize}
          selectedClassId={selectedClassId}
          icon={<AddIcon />} />
        <FormControl >
          <lable>{t("Select Class")}</lable>
          <Select
            value={selectedClassId}
            onChange={handleSelectChange}
            sx={{ marginLeft: 2, minWidth: 150 }}
          >
            {
              classesData&&
              classesData.map((classItem) => (
                <MenuItem dir={sidebarRTL ? "rtl" : "ltr"} key={classItem.id} value={classItem.id}>
                {classItem.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
      <CustomTableBox tableData={tableData}
        action={absenceSearch}
        classId={selectedClassId}
        CustomPagenation={
      <CustomPagination
          nextPage={nextPage}
          classId={selectedClassId}
          pageSize={pageSize}
          currentPage={currentPage}
          prevPage={prevPage}
          lastPage={lastPage}
            firstPage={firstPage}
            action={fetchAbsenceDataByPage}
        />
      }
      />
    </Box>
  );
};
export default AbsenceTable;
