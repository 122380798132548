import React from 'react'
import { Box, Button, Dialog, DialogContent, DialogTitle, TextField, useTheme } from '@mui/material'
import { useFormik } from "formik";
import * as yup from 'yup';
import { useMediaQuery } from "@mui/material";
import { tokens } from '../../theme';
import { useDispatch, useSelector } from 'react-redux'; // Import the useDispatch hook
import { useTranslation } from 'react-i18next';
import { editFullMark, fetchFullMarkData } from '../../redux/FullMarks';
import EditIcon from '@mui/icons-material/Edit';
import { useSidebarContext } from '../global/sidebar/sidebarContext';
const EditFullMark = (props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch(); // Get the dispatch function
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const loading = useSelector((state) => state.FullMarks.loading);
    const checkoutSchema = yup.object().shape({
        full_mark: yup.number().required("Required"),
    })
    const formik = useFormik({
        initialValues: {
            full_mark: props.fullMark,
        }, validationSchema: checkoutSchema,
        onSubmit: values => {
            handleFormSubmit(values);
        }
    });
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleFormSubmit = async (values) => {
        let markID = props.ID
        const markInfo = {
            values: values,
            id: markID,
        }
        const info = {
            id: props.examId
        }
        await dispatch(editFullMark(markInfo))
        await dispatch(fetchFullMarkData(info));
        await handleClose()
    }
    const { sidebarRTL } = useSidebarContext();

    return (
        <Box m="20px">
            <Button
                variant="contained"
                sx={props.sx}
                onClick={handleClickOpen}
                endIcon={props.icon}
            >
                {t("Edit")}
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle sx={{ fontWeight: "bold", fontSize: "22px" }} align={sidebarRTL ? "right" : "left"}>{t("Edit", " ", "full_marks")}</DialogTitle>
                <DialogContent dir={sidebarRTL?"rtl":"ltr"}>
                    <form onSubmit={formik.handleSubmit}>
                    <lable  style={{fontSize:"18px",fontWeight: "Bold"}}>{t("Full Mark")}</lable>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            placeholder={t("Full Mark")}
                            dir={sidebarRTL ? "rtl" : "ltr"}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.full_mark}
                            name="full_mark"
                            error={!!formik.touched.full_mark && !!formik.errors.full_mark}
                            helperText={formik.touched.full_mark && formik.errors.full_mark}
                            sx={{ gridColumn: "span 2" }}
                            inputProps={{
                                style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
                            }}
                        />
                        <Box display="flex" mt="20px">
                            <Button
                                type="submit"
                                sx={{
                                    backgroundColor: colors.blueAccent[700],
                                    color: colors.grey[100],
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    padding: '10px 20px',
                                }}
                                disabled={loading ? true : false}
                                variant="outlined"
                                onClick={handleClickOpen}
                                endIcon={<EditIcon />}
                            >
                                {loading ? "wait..." : t("Edit")}
                            </Button>
                        </Box>
                    </form>

                </DialogContent>
            </Dialog>
        </Box>
    );
}

export default EditFullMark