import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import { tokens } from "../../theme";
import AddIcon from "@mui/icons-material/Add";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import {  selectExpenses } from "../../redux/gradeExpenses";
import { addStudentInstallments, getAllStudentInstallments } from "../../redux/StudentInstallments";
import { getAllInstallmentsData } from "../../redux/StudentInstallments";
import { useParams } from "react-router-dom";

const AddStudentInstallment = (props) => {
  const {student_id} = useParams()
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { sidebarRTL } = useSidebarContext();
  const loading = useSelector((state) => state.loading);

  const checkoutSchema = yup.object().shape({
    payment_date: yup.date().required("Required"),
    total_expenses: yup.number().required("Required & must be a number"),
  });

  const formik = useFormik({
    initialValues: {
      student_id:props.id,
      payment_date:"",
      total_expenses:""
    },
    validationSchema: checkoutSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async () => {
    const info = {
      id: props.id,
      pageSize: props.pageSize,
    };
    setOpen(false);
    await dispatch(getAllStudentInstallments(info))
    await dispatch(getAllInstallmentsData(student_id))
    formik.resetForm()
  };
  useEffect(() => {
    dispatch(selectExpenses())
  },[open===true])
  const handleFormSubmit = async (values) => {
    const formValues = {
      ...values,
      payment_date:values.payment_date.format("YYYY-MM-DD"),
      
    }
    await dispatch(addStudentInstallments(formValues))
      .then((res) =>
      res.payload.code===201?handleClose():setOpen(true)
    );
  };

  // const setFormikFieldValue = (field, value) => {
  //   formik.setFieldValue(field, value);
  // };
  return (
    <Box m="20px" >
      <Button
        sx={{
          backgroundColor: colors.blueAccent[700],
          color: colors.grey[100],
          fontSize: "14px",
          fontWeight: "bold",
          padding: "10px 20px",
          display: props.show ? "inline-flex" : "none"
        }}
        variant="outlined"
        onClick={handleClickOpen}
        endIcon={<AddIcon />}
      >
        {t("Add")}
      </Button>
      <Dialog
        sx={{ width: { xs: "100%", md: "50%" }, margin: "auto", padding: "0" }}
        open={open}
        onClose={handleClose}
      >
        <DialogContent dir={sidebarRTL?"rtl":"ltr"}>
          <DialogTitle sx={{ fontWeight: "bold", fontSize: "22px" }} align={sidebarRTL ? "right" : "left"}>{t("Add", " ", "prices")}</DialogTitle>
          <form onSubmit={formik.handleSubmit} dir={sidebarRTL?"ltr":"rtl"}>
            <Box
              dir={sidebarRTL?"rtl":"ltr"}
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <Box display="flex" flexDirection="column" sx={{ gridColumn: "span 4" }}>
                <lable style={{fontSize:"20px",fontWeight:"bold"}}>{t("total_expenses")}</lable>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.total_expenses}
                  name="total_expenses"
                  error={!!formik.touched.total_expenses && !!formik.errors.total_expenses}
                  helperText={formik.touched.total_expenses && formik.errors.total_expenses}
                  sx={{ gridColumn: "span 4" }}
                  inputProps={{
                    style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
                  }}
                    />
                  </Box>
              <lable style={{fontSize:"18px",fontWeight: "Bold"}}>{t("payment_date")}</lable>
              <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth={true}>
                <DatePicker
                  dir={sidebarRTL ? "rtl" : "ltr"}
                  sx={{ direction: sidebarRTL ? "rtl" : "ltr",gridColumn:"span 4" }}
                  placeholder={t("Date Picker")}
                  format="YYYY/MM/DD"
                  value={formik.values.payment_date}
                  onBlur={() => formik.setFieldTouched("payment_date", true)}
                  onChange={(date) => formik.setFieldValue("payment_date", date)}
                  name="payment_date"
                  error={
                    !!formik.touched.payment_date && !!formik.errors.payment_date
                  }
                  helperText={
                    formik.touched.payment_date && formik.errors.payment_date
                  }
                  renderInput={(props) => (
                    <TextField {...props} variant="outlined" fullWidth />
                  )}
                />
              </LocalizationProvider>
              
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                sx={{
                  backgroundColor: colors.blueAccent[700],
                  color: colors.grey[100],
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 20px",
                }}
                disabled={loading ? true : false}
                variant="outlined"
                onClick={handleClickOpen}
              >
                {loading ? t("wait") : t("Add")}
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default AddStudentInstallment;
