import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import { tokens } from "../../theme";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { addPlane, studyPlaneData } from "../../redux/studypalne";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTranslation } from "react-i18next";
import { useSidebarContext } from "../global/sidebar/sidebarContext";

const PlaneForm = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { sidebarRTL } = useSidebarContext();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [typeID, setTypeID] = useState();
  const loading = useSelector((state) => state.studyPlane.loading);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const handleSelection = (selectedValue) => {
    setTypeID(selectedValue);
  };
  const validationSchema = yup.object().shape({
    name: yup.string().required("Required"),
    type: yup.number().required("Required"), // Added validation for the type field
    created_at: yup.date().required("Required"), // Added validation for the type field
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      created_at: "",
      type: "", // Added type field
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const pageSize = props.pageSize;
  const info = {
    pageSize,
  };
  const handleSubmit = async (values) => {
    try {
      const formattedDate = values.created_at.format("YYYY-MM-DD"); // Format the date
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("created_at", formattedDate); // Use the formatted date
      formData.append("type", values.type);
      await dispatch(addPlane(formData))
        .then((res) => res.payload.code === 201 && setOpen(false))
        .then(() => {
          dispatch(studyPlaneData(info));
        });
    } catch (error) {
      console.error("Error during form submission:", error);
    }
  };
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = async () => {
    setOpen(false);
    formik.resetForm();
  };
  return (
    <Box m="20px">
      
      <Button
        sx={{
          backgroundColor: colors.blueAccent[700],
          color: colors.grey[100],
          fontSize: "14px",
          fontWeight: "bold",
          padding: "10px 20px",
          display: props.show ? "inline-flex" : "none",
        }}
        variant="outlined"
        onClick={handleClickOpen}
        
      >
        {t("Add")}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          sx={{ fontWeight: "bold", fontSize: "22px" }}
          align={sidebarRTL ? "right" : "left"}
        >
          {t("Add")}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <Box
              display="grid"
              dir={sidebarRTL?"rtl":"ltr"}
              gap="20px"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <lable style={{fontSize:"18px",fontWeight: "Bold"}}>{t("Name")}</lable>
              <TextField
                fullWidth={true}
                variant="outlined"
                type="text"
                placeholder={t("Name")}
                dir={sidebarRTL ? "rtl" : "ltr"}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                name="name"
                error={!!formik.touched.name && !!formik.errors.name}
                helperText={formik.touched.name && formik.errors.name}
              />
              <lable style={{fontSize:"18px",fontWeight: "Bold"}}>{t("StartDate")}</lable>
              <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth={true}>
                <DatePicker
                  dir={sidebarRTL ? "rtl" : "ltr"}
                  sx={{ direction: sidebarRTL ? "rtl" : "ltr" }}
                  placeholder={t("Date Picker")}
                  format="YYYY/MM/DD"
                  value={formik.values.created_at}
                  onBlur={() => formik.setFieldTouched("created_at", true)}
                  onChange={(date) => formik.setFieldValue("created_at", date)}
                  name="created_at"
                  error={
                    !!formik.touched.created_at && !!formik.errors.created_at
                  }
                  helperText={
                    formik.touched.created_at && formik.errors.created_at
                  }
                  renderInput={(props) => (
                    <TextField {...props} variant="outlined" fullWidth />
                  )}
                />
              </LocalizationProvider>
              <FormControl
                fullWidth
                variant="outlined"
                error={!!formik.touched.type && !!formik.errors.type}
              >
                <lable style={{fontSize:"18px",fontWeight: "Bold"}}>{t("Type")}</lable>
                <Select
                  sx={{ direction: sidebarRTL ? "rtl" : "ltr",marginTop:"1rem" }}
                  value={formik.values.type}
                  onBlur={() => formik.setFieldTouched("type", true)}
                  onChange={formik.handleChange}
                  name="type"
                >
                  <MenuItem dir={sidebarRTL ? "rtl" : "ltr"} value="" disabled>
                    {t("Select a type")}
                  </MenuItem>
                  <MenuItem dir={sidebarRTL ? "rtl" : "ltr"} value={1}>{t("Holiday")}</MenuItem>
                  <MenuItem dir={sidebarRTL ? "rtl" : "ltr"} value={2}>{t("Occasion")}</MenuItem>
                </Select>
                {formik.touched.type && formik.errors.type && (
                  <div style={{ color: "red" }}>{formik.errors.type}</div>
                )}
              </FormControl>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                sx={{
                  backgroundColor: colors.blueAccent[700],
                  color: colors.grey[100],
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 20px",
                }}
                variant="outlined"
                endIcon={<AddIcon />}
                disabled={loading ? true : false}
              >
                {loading ? t("wait") : t("Add")}
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default PlaneForm;
