import React, { useState, useEffect } from "react";
import {
  Box,
  ButtonGroup,
  LinearProgress,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import AddIcon from "@mui/icons-material/Add";
import Header from "../../components/Header";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getExamAssignment,
  getExams,
  getSubjects,
} from "../../redux/ExamAssignment";
import { getGrades } from "../../redux/Subjects";
import { getClasses } from "../../redux/Grades";
import DeleteExam from "./DeleteExam";
import AddExam from "./AddExam";
import EditExam from "./EditExam";
import Pagenation from "./pagenation";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import AddExelFile from "./AddExelFile";

const ExamAssignmentTable = () => {
  const permission =
    useSelector((state) => state.user.userInfo.data.permissions) || [];
  const { sidebarRTL } = useSidebarContext();
  const { t } = useTranslation();
  const theme = useTheme();
  const [pageSize, setPageSize] = useState(10);
  const colors = tokens(theme.palette.mode);
  const [subjectId, setsubjectId] = useState("");
  const [gradeId, setgradeId] = useState("");
  const [classId, setclassId] = useState("");
  const [examId, setexamId] = useState("");
  const dispatch = useDispatch();

  const handleGradeChange = (event) => {
    const selectedGradeId = event.target.value;
    setgradeId(selectedGradeId);
  };

  const handleSubjectChange = (event) => {
    const selectedSubjectId = event.target.value;
    setsubjectId(selectedSubjectId);
  };

  const handleExamChange = (event) => {
    const selectedExamId = event.target.value;
    setexamId(selectedExamId);
  };

  const handleClassChange = (event) => {
    const selectedClassId = event.target.value;
    setclassId(selectedClassId);
  };

  useEffect(() => {
    dispatch(getGrades());
    dispatch(getClasses());
    dispatch(getExams());
    dispatch(getSubjects());
  }, [dispatch]);

  useEffect(() => {
    if (gradeId && subjectId && examId) {
      dispatch(
        getExamAssignment({
          class_id: classId,
          grade_id: gradeId,
          subject_id: subjectId,
          exam_id: examId,
          pageSize: pageSize,
        })
      );
    }
  }, [dispatch, gradeId, subjectId, examId, classId, pageSize]);

  const examAssignment =
    useSelector((state) => state.examAssignment?.exam_assignment?.data) || [];
  const grades = useSelector((state) => state.subjects?.grades?.data) || [];
  const classes = useSelector((state) => state.grades?.classes?.data) || [];
  const exams = useSelector((state) => state.examAssignment?.exams?.data) || [];
  const subjects =
    useSelector((state) => state.examAssignment?.subjects?.data) || [];
  const loading = useSelector((state) => state.examAssignment.loading);

  useEffect(() => {
    if (grades && subjects && exams) {
      if (grades.length > 0 && gradeId === "") setgradeId(grades[0].id);
      if (subjects.length > 0 && subjectId === "") setsubjectId(subjects[0].id);
      if (exams.length > 0 && examId === "") setexamId(exams[0].id);
    }
  }, [grades, exams, subjects]);

  const createdWidth =
    permission.includes("update-exam_assignment") &&
    permission.includes("delete-exam_assignment")
      ? 150
      : 250;
  const nameWidth =
    permission.includes("update-exam_assignment") &&
    permission.includes("delete-exam_assignment")
      ? 250
      : 370;
  const idWidth =
    permission.includes("update-exam_assignment") &&
    permission.includes("delete-exam_assignment")
      ? 70
      : 100;

  const columns = [
    { field: "assignmentId", headerName: t("ID"), width: idWidth },
    {
      field: "name",
      headerName: t("Name"),
      width: nameWidth,
      cellClassName: "name-column--cell",
    },
    {
      field: "schoolStudentId",
      headerName: t("School Student Id"),
      width: 200,
      cellClassName: "name-column--cell",
    },
    {
      field: "obtainedMark",
      headerName: t("obtained-Mark"),
      width: 150,
      cellClassName: "name-column--cell",
    },
    {
      field: "fullMark",
      headerName: t("Full Mark"),
      width: 150,
      cellClassName: "name-column--cell",
    },
    {
      field: "percentage",
      headerName: t("Percentage"),
      width: 150,
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <>{params.row.examRatio.percentage}</>
      )
    },
    {
      field: "examStatus",
      headerName: t("examStatus"),
      width: 150,
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <>{t(`${params.row.examRatio.status}`)}</>
      )
    },
    
    {
      field: "createdAt",
      headerName: t("created at"),
      width: createdWidth,
      cellClassName: "name-column--cell",
    },
    
    ...(permission.includes("update-exam_assignment") &&
    permission.includes("delete-exam_assignment")
      ? [
          {
            field: "actions",
            headerName: t("Actions"),
            width: 300,
            renderCell: (params) => (
              <ButtonGroup
                sx={{
                  display: "flex",
                  flexDirection: sidebarRTL
                    ? "row-reverse !important"
                    : "row !important",
                  alignItems: "center",
                }}
              >
                <EditExam
                  sx={{
                    background: `${colors.primary[600]}`,
                    m: 4,
                    display: permission.includes("update-exam_assignment")
                      ? "inline-flex"
                      : "none",
                  }}
                  obtainedMark={params.row.obtainedMark}
                  id={params.row.assignmentId}
                  grade_id={gradeId}
                  class_id={classId}
                  exam_id={examId}
                  subject_id={subjectId}
                  pageSize={pageSize}
                  icon={<EditIcon />}
                />
                <DeleteExam
                  sx={{
                    background: `${colors.redAccent[600]}`,
                    display: permission.includes("delete-exam_assignment")
                      ? "inline-flex"
                      : "none",
                  }}
                  id={params.row.assignmentId}
                  gradeId={gradeId}
                  classId={classId}
                  examId={examId}
                  subjectId={subjectId}
                  pageSize={pageSize}
                  icon={<DeleteForeverIcon />}
                />
              </ButtonGroup>
            ),
          },
        ]
      : []),
  ];

  const examAssignmentWithId = examAssignment.map((item, index) => ({
    ...item,
    id: index + 1,
  }));

  return (
    <Box m="20px">
      <Box
        display="flex"
        flexDirection={sidebarRTL ? "row-reverse" : "row"}
        justifyContent="space-between"
        alignItems="center"
      >
        <Header title={t("All-Exam-Assignment")} />
      </Box>

      <Box
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        flexDirection={{ xs: "column", md: "row" }}
      >
        <AddExam
          style={{marginTop:".5rem"}}
            show={permission.includes("store-exam_assignment")}
            subjects={subjects}
            exams={exams}
            grades={grades}
            pageSize={pageSize}
            icon={<AddIcon />}
          />
        <AddExelFile
            show={permission.includes("store-exam_assignment")}
            subjects={subjects}
            exams={exams}
            grades={grades}
            pageSize={pageSize}
          />
        <Box
          dir={sidebarRTL ? "rtl" : "ltr"}
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
        >
          <labe
            style={{
              fontSize: "18px",
              marginBottom: ".5rem",
              fontWeight: "bold",
            }}
          >
            {t("Select Grade")}
          </labe>
          <Select
            id="grade-select"
            value={gradeId}
            onChange={handleGradeChange}
            sx={{ marginLeft: 2, minWidth: 150 }}
          >
            {grades.map((grade) => (
              <MenuItem dir={sidebarRTL ? "rtl" : "ltr"} key={grade.id} value={grade.id}>
                {grade.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box
          dir={sidebarRTL ? "rtl" : "ltr"}
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
        >
          <label
            style={{
              fontSize: "18px",
              marginBottom: ".5rem",
              fontWeight: "bold",
            }}
          >
            {t("Select Subject")}
          </label>
          <Select
            id="subject-select"
            value={subjectId}
            onChange={handleSubjectChange}
            sx={{ marginLeft: 2, minWidth: 150 }}
          >
            {subjects.map((subject) => (
              <MenuItem dir={sidebarRTL ? "rtl" : "ltr"} key={subject.id} value={subject.id}>
                {subject.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box
          dir={sidebarRTL ? "rtl" : "ltr"}
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
        >
          <label
            style={{
              fontSize: "18px",
              marginBottom: ".5rem",
              fontWeight: "bold",
            }}
          >
            {t("Select Exam")}
          </label>
          <Select
            id="exam-select"
            value={examId}
            onChange={handleExamChange}
            sx={{ marginLeft: 2, minWidth: 150 }}
          >
            {exams.map((exam) => (
              <MenuItem dir={sidebarRTL ? "rtl" : "ltr"} key={exam.id} value={exam.id}>
                {exam.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box
          dir={sidebarRTL ? "rtl" : "ltr"}
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
        >
          <label
            style={{
              fontSize: "18px",
              marginBottom: ".5rem",
              fontWeight: "bold",
            }}
          >
            {t("Select Class")}
          </label>
          <Select
            id="class-select"
            value={classId}
            onChange={handleClassChange}
            sx={{ marginLeft: 2, minWidth: 150 }}
          >
            {classes.map((classItem) => (
              <MenuItem dir={sidebarRTL ? "rtl" : "ltr"} key={classItem.id} value={classItem.id}>
                {classItem.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Box
        m="8px 0 0 0"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
            fontWeight: "bold",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            fontWeight: "bold",
            fontSize: "20px",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiChackbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiButtonGroup-root.MuiButtonGroup-outlined.css-iajp3t-MuiButtonGroup-root":
            {
              display: "flex",
              alignItems: "center",
              flexDirection: sidebarRTL ? "row-reverse" : "row",
              gap: sidebarRTL ? "35px" : "0px",
            },
          "& .MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
            justifyContent: sidebarRTL
              ? "flex-end !important"
              : "flex-start !important",
          },
          "& .MuiDataGrid-columnHeaderDraggableContainer": {
            flexDirection: sidebarRTL
              ? "row-reverse !important"
              : "row !important",
          },
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            justifyContent: sidebarRTL ? "end !important" : "start !important",
            flexDirection: sidebarRTL
              ? "row-reverse !important"
              : "row !important",
          },
        }}
      >
        {loading ? (
          <LinearProgress />
        ) : (
          <DataGrid
            sx={{
              fontWeight: "bold",
              fontSize: "14px",
              }}
              disableSelectionOnClick={true}

            rows={examAssignmentWithId}
            loading={!examAssignment}
            autoHeight
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 15]}
            componentsProps={{
              pagination: {
                labelRowsPerPage: t("rowsPerPageText"),
                dir: sidebarRTL ? "rtl" : "ltr",
              },
            }}
            columns={sidebarRTL ? columns.reverse() : columns}
          />
        )}
        <Pagenation pageSize={pageSize} />
      </Box>
    </Box>
  );
};
export default ExamAssignmentTable;
