import React, { useEffect, useState } from "react";
import { Box, ButtonGroup, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Pagenation from "./pagenation";
import AddParents from "./AddParent";
import DeleteParent from "./DeleteParent";
import EditParent from "./EditParent";
import { fetchParentsDataByPage, parentData, parentSearchData } from "../../redux/parents";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import { useTranslation } from "react-i18next";
import CustomPagenation from "../../components/CustomPagenation/CustomPagenation";
import CustomTableBox from "../../components/customTableBox/CustomTableBox";

const ParentsTable = () => {
  const firstPage = useSelector(state => state.parentData.parentLinks.first)
    const nextPage = useSelector(state => state.parentData.parentLinks.next)
    const lastPage = useSelector(state => state.parentData.parentLinks.last)
    const prevPage = useSelector(state => state.parentData.parentLinks.prev)
    const currentPage = useSelector(state => state.parentData.currentPage)
  const { t } = useTranslation();
  const { sidebarRTL } = useSidebarContext();
  const dispatch = useDispatch();
  const permission =
    useSelector((state) => state.user.userInfo.data.permissions) || [];
  const getParentData =
    useSelector((state) => state.parentData.parent.data) || [];
  const loading = useSelector((state) => state.parentData.loading);
  const theme = useTheme();
  const [pageSize, setPageSize] = useState(10);
  const colors = tokens(theme.palette.mode);
  const nameWidth =
    permission.includes("update-parent") && permission.includes("delete-parent")
      ? 250
      : 250;
  const idWidth =
    permission.includes("update-parent") && permission.includes("delete-parent")
      ? 100
      : 200;
  const columns = [
    { field: "id", headerName: t("ID"), width: idWidth },
    { field: "phone", headerName: t("Phone"), width: idWidth },
    {
      field: "name",
      headerName: t("Name"),
      width: nameWidth,
      cellClassName: "name-column--cell",
    },
    { field: "username", headerName: t("Username"), width: nameWidth / 2 },
    ...(permission.includes("delete-parent") &&
    permission.includes("update-parent")
      ? [
          {
            field: "actions",
            headerName: t("Actions"),
            width: 380,
            renderCell: (params) => (
              <ButtonGroup
                sx={{
                  display: "flex",
                  flexDirection: sidebarRTL ? "row-reverse" : "row",
                }}
              >
                <EditParent
                  pageSize={pageSize}
                  sx={{
                    background: `${colors.primary[600]}`,
                    display: permission.includes("update-parent")
                      ? "inline-flex"
                      : "none",
                  }}
                  ID={params.id}
                  icon={<EditIcon />}
                  name={params.row.name}
                  userName={params.row.username}
                  phone={params.row.phone}
                />
                <DeleteParent
                  pageSize={pageSize}
                  sx={{
                    background: `${colors.redAccent[600]}`,
                    display: permission.includes("delete-parent")
                      ? "inline-flex"
                      : "none",
                  }}
                  icon={<DeleteIcon />}
                  id={params.id}
                />
              </ButtonGroup>
            ),
          },
        ]
      : []),
  ];
  useEffect(() => {
    const info = {
      pageSize: pageSize,
    };
    dispatch(parentData(info)); // Initial data fetching
  }, [dispatch, pageSize]);
  const tableData = {
    rows:getParentData.length>0 && getParentData,
    columns:columns,
    loading:loading===true,
    pageSize:pageSize,
    onPageSizeChange:(newPageSize) => setPageSize(newPageSize)
  }
  return (
    <Box m="20px">
      <Box
        display="flex"
        flexDirection={sidebarRTL ? "row-reverse" : "row"}
        justifyContent="space-between"
        alignItems="center"
      >
        <Header title={t("Parents")} />
        <AddParents
          show={permission.includes("update-parent")}
          pageSize={pageSize}
        />
      </Box>
      <CustomTableBox
        tableData={tableData}
        action={parentSearchData}
          CustomPagenation={
            <CustomPagenation
              pageSize={pageSize}
              nextPage={nextPage}
              lastPage={lastPage}
              prevPage={prevPage}
              firstPage={firstPage}
              currentPage={currentPage}
              action={fetchParentsDataByPage}
            />
          }
          />
    </Box>
  );
};
export default ParentsTable;
