import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  TextField,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import { tokens } from "../../theme";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux"; // Import the useDispatch hook
import { addParent, parentData } from "../../redux/parents";
import { IconButton, InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";
import { useSidebarContext } from "../global/sidebar/sidebarContext";

const AddParents = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const { sidebarRTL } = useSidebarContext();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const loading = useSelector((state) => state.parentData.loading);
  const initialValues = {
    type: "parent",
    name: "",
    username: "",
    phone: "",
    password: "",
    password_confirmation: "",
  };
  const checkoutSchema = yup.object().shape({
    name: yup.string().required("Required"),
    username: yup.string().required("Required"),
    phone: yup.number().required("Required"),
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters long")
      .matches(
        /^(?=.*[A-Z][a-z])/,
        "Password must contain at least one uppercase letter and least one lowercase letter"
      )
      .required("Password is required"),
    password_confirmation: yup
      .string()
      .required("Required")
      .min(8)
      .oneOf([yup.ref("password")], "Passwords must match"),
  });
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const [formik, setFormik] = useState({
    values: initialValues, // Set the initial values here
  });
  const handleClose = () => {
    setOpen(false);
    // Reset the form values to their initial state
    setFormik({
      values: initialValues,
    });
  };
  const handleFormSubmit = async (values) => {
    const info = {
      pageSize: props.pageSize,
    };
    await dispatch(addParent(values))
      .then((res)=>res.payload.code==201 && dispatch(parentData(info))&& handleClose())
  };
  return (
    <Box m="20px">
      <Button
        sx={{
          backgroundColor: colors.blueAccent[700],
          color: colors.grey[100],
          fontSize: "14px",
          fontWeight: "bold",
          padding: "10px 20px",
          display: props.show ? "inline-flex" : "none",
        }}
        variant="outlined"
        onClick={handleClickOpen}
        endIcon={<AddIcon />}
      >
        {t("Add")}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  dir={sidebarRTL?"rtl":"ltr"}
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                >
                  <Box display="flex" flexDirection="column" sx={{gridColumn: "span 2"}}>
                  <lable style={{fontSize:"18px",fontWeight: "Bold"}}>{t("Name")}</lable>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    placeholder={t("Name")}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    name="name"
                    error={!!touched.name && !!errors.name}
                    helperText={touched.name && errors.name}
                    sx={{ gridColumn: "span 2" }}
                    dir={sidebarRTL ? "rtl" : "ltr"}
                    inputProps={{
                      style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
                    }}
                    />
                  </Box>
                  <Box display="flex" flexDirection="column" sx={{gridColumn: "span 2"}}>
                  <lable style={{fontSize:"18px",fontWeight: "Bold"}}>{t("Username")}</lable>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    placeholder={t("Username")}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.username}
                    name="username"
                    error={!!touched.username && !!errors.username}
                    helperText={touched.username && errors.username}
                    sx={{ gridColumn: "span 2" }}
                    dir={sidebarRTL ? "rtl" : "ltr"}
                    inputProps={{
                      style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
                    }}
                    />
                  </Box>
                  <Box display="flex" flexDirection="column" sx={{gridColumn: "span 2"}}>
                  <lable style={{fontSize:"18px",fontWeight: "Bold"}}>{t("Password")}</lable>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    placeholder={t("Password")}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    name="password"
                    error={!!touched.password && !!errors.password}
                    helperText={touched.password && errors.password}
                    sx={{ gridColumn: "span 2" }}
                    dir={sidebarRTL ? "rtl" : "ltr"}
                    InputProps={{
                      style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    />
                  </Box>
                  <Box display="flex" flexDirection="column" sx={{gridColumn: "span 2"}}>
                  <lable style={{fontSize:"18px",fontWeight: "Bold"}}>{t("Password Confirmation")}</lable>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    placeholder={t("Password Confirmation")}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password_confirmation}
                    name="password_confirmation"
                    error={
                      !!touched.password_confirmation &&
                      !!errors.password_confirmation
                    }
                    helperText={
                      touched.password_confirmation &&
                      errors.password_confirmation
                    }
                    sx={{ gridColumn: "span 2" }}
                    dir={sidebarRTL ? "rtl" : "ltr"}
                    InputProps={{
                      style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    />
                  </Box>
                  <Box display="flex" flexDirection="column" sx={{gridColumn: "span 2"}}>
                  <lable style={{fontSize:"18px",fontWeight: "Bold"}}>{t("Phone")}</lable>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    placeholder={t("Phone")}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phone}
                    name="phone"
                    error={!!touched.phone && !!errors.phone}
                    helperText={touched.phone && errors.phone}
                    sx={{ gridColumn: "span 2" }}
                    dir={sidebarRTL ? "rtl" : "ltr"}
                    inputProps={{
                      style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
                    }}
                    />
                  </Box>
                </Box>
                <Box display="flex" justifyContent="end" mt="20px">
                  <Button
                    type="submit"
                    sx={{
                      backgroundColor: colors.blueAccent[700],
                      color: colors.grey[100],
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px 20px",
                    }}
                    disabled={loading ? true : false}
                    variant="outlined"
                    onClick={handleClickOpen}
                    endIcon={<AddIcon />}
                  >
                    {loading ? "wait..." : t("Add")}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default AddParents;
