import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import {
  getFullMarkGrades,
  getFullMarkSubjects,
} from "../../redux/FullMarks";
import {
  Select,
  FormControl,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSidebarContext } from "../global/sidebar/sidebarContext";

const DropDownGradesSubjects = ({ setFormikValues }) => {
  const {t} = useTranslation()
  const [selectedGradeId, setSelectedGradeId] = useState();
  const [selectedGradeExpenses, setselectedGradeExpenses] = useState();
  const AllGrades =
    useSelector((state) => state.FullMarks.getGradesData.data) || [];
  
  const dispatch = useDispatch();
  const gradeExpenses = useSelector(state => state.gradeExpenses.selectMenuExpenses.data) || [];

  useEffect(() => {
    dispatch(getFullMarkGrades());
  }, [dispatch]);

  useEffect(() => {
    if (selectedGradeId) {
      dispatch(getFullMarkSubjects({ selectedGradeId }));
    }
  }, [dispatch, selectedGradeId]);

  const handleSelectChangeGrade = (event) => {
    const selectedGradeId = event.target.value;
    setSelectedGradeId(selectedGradeId);
    setselectedGradeExpenses(null);
    setFormikValues("grade_id", selectedGradeId);
  };

  const handleSelectChangeGradeExpenses = (event) => {
    setselectedGradeExpenses(event.target.value);
    setFormikValues("expense_id", event.target.value);
  };


  const { sidebarRTL } = useSidebarContext();

  return (
    <Box m="20px" display="flex" flexDirection="column" dir={sidebarRTL?"rtl":"ltr"}>
      <FormControl variant="outlined">
        <lable style={{fontSize:"18px",fontWeight: "Bold"}}>{t("Grade")}</lable>
        <Select
          fullWidth
          value={selectedGradeId}
          onChange={handleSelectChangeGrade}
          dir={sidebarRTL ? "rtl" : "ltr"}
        >
          {AllGrades &&
            AllGrades.map((gradeItem) => (
              <MenuItem dir={sidebarRTL ? "rtl" : "ltr"}
                key={gradeItem.id} value={gradeItem.id}>
                {gradeItem.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined" sx={{ mt: 2 }}>
        <lable style={{fontSize:"18px",fontWeight: "Bold"}}>{t("gradeExpenses")}</lable>
        <Select
          fullWidth
          value={selectedGradeExpenses}
          onChange={handleSelectChangeGradeExpenses}
          dir={sidebarRTL ? "rtl" : "ltr"}
        >
          {gradeExpenses &&
            gradeExpenses.map((item) => (
              <MenuItem dir={sidebarRTL ? "rtl" : "ltr"}
                key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default DropDownGradesSubjects;
