import { useState } from "react";
import { Menu, Sidebar, MenuItem } from "react-pro-sidebar";
import { useProSidebar } from "react-pro-sidebar";
import { useSidebarContext } from "./sidebarContext";
import { Link } from "react-router-dom";
import { tokens } from "../../../theme";
import { useTheme, Box, Typography, IconButton } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useTranslation } from "react-i18next";
import {
  Grade,
  Subject,
  AdminPanelSettings,
  Map,
  School,
  Class,
  Stars,
  SchoolOutlined,
  GroupAdd,
  PersonAdd,
  MenuOutlined,
} from "@mui/icons-material";
import SummarizeIcon from "@mui/icons-material/Summarize";
import HealingIcon from "@mui/icons-material/Healing";
import ArticleIcon from "@mui/icons-material/Article";
import GradingIcon from "@mui/icons-material/Grading";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import Man4Icon from "@mui/icons-material/Man4";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import EngineeringIcon from "@mui/icons-material/Engineering";
import { useSelector } from "react-redux";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import CustomAccordion from "../../../components/Accordion/Accordion";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SettingsIcon from '@mui/icons-material/Settings';
import PaymentIcon from '@mui/icons-material/Payment';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import ListAltIcon from '@mui/icons-material/ListAlt';
const Item = ({ title, to, icon, selected, setSelected, permission }) => {
  const permissions =
    useSelector((state) => state.user.userInfo.data?.permissions) || [];
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const hasPermission = permissions.includes(permission);

  return hasPermission ? (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
      routerLink={<Link to={to} />}
    >
      <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
        {title}
      </Typography>
    </MenuItem>
  ) : null;
};

const MyProSidebar = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selected, setSelected] = useState("Dashboard");
  const { sidebarRTL } = useSidebarContext();
  const { collapseSidebar, toggleSidebar, collapsed, broken } = useProSidebar();
  const options = [
    <Item
      title={t("terms")}
      to="/terms"
      icon={<Class />}
      selected={selected}
      setSelected={setSelected}
      //  permission="all-term"
      permission="all-class"
    />,
    <Item
      title={t("Occasions")}
      to="/studyplane"
      icon={<Map />}
      selected={selected}
      setSelected={setSelected}
      permission="all-study_plan"
    />,
    <Item
      title={t("ExamSchedule")}
      to="/ExamSchedule"
      icon={<CalendarMonthOutlinedIcon />}
      selected={selected}
      setSelected={setSelected}
      permission="all-exam_schedule"
    />,
  ];
  const StudentManagment = [
    <Item
      title={t("Parents")}
      to="/parents"
      icon={<FamilyRestroomIcon />}
      selected={selected}
      setSelected={setSelected}
      permission="all-parent"
    />,
    <Item
      title={t("Students")}
      to="/student"
      icon={<PersonAdd />}
      selected={selected}
      setSelected={setSelected}
      permission="all-student"
    />,
    <Item
      title={t("Bans")}
      to="/bans"
      icon={<NotInterestedIcon />}
      selected={selected}
      setSelected={setSelected}
      permission="all-ban"
    />,
    <Item
      title={t("Guardians")}
      to="/guardians"
      icon={<ArticleIcon />}
      selected={selected}
      setSelected={setSelected}
      permission="all-summon_guardian"
    />,
    <Item
      title={t("Appreciations")}
      to="/appreciations"
      icon={<Stars />}
      selected={selected}
      setSelected={setSelected}
      permission="all-appreciation"
    />,
    <Item
      title={t("Reports")}
      to="/reports"
      icon={<SummarizeIcon />}
      selected={selected}
      setSelected={setSelected}
      permission="all-absence"
    />,
    <Item
      title={t("Add Absence")}
      to="/absence"
      icon={<GroupAdd />}
      selected={selected}
      setSelected={setSelected}
      permission="all-absence"
    />,
  ];
  const ExamManagment = [
    <Item
      title={t("Exams")}
      to="/exams"
      icon={<ListAltIcon />}
      selected={selected}
      setSelected={setSelected}
      permission="all-exam"
    />,
    <Item
      title={t("full_marks")}
      to="/fullmarks"
      icon={<GradingIcon />}
      selected={selected}
      setSelected={setSelected}
      permission="all-custom_full_marks"
    />,
    <Item
      title={t("Exam Assignment")}
      to="/exam-assignment"
      icon={<AssignmentTurnedInIcon />}
      selected={selected}
      setSelected={setSelected}
      permission="all-exam_assignment"
    />,
  ];
  const expensesManagment = [
    <Item
      title={t("Expenses")}
      to="/expenses"
      icon={<MonetizationOnIcon />}
      selected={selected}
      setSelected={setSelected}
      permission="all-expenses"
    />,
    <Item
      title={t("gradeExpenses")}
      to="/showGradeExpenses"
      icon={<MonetizationOnIcon />}
      selected={selected}
      setSelected={setSelected}
      permission="all-grade_expenses"
    />,
    <Item
      title={t("StudentsExpenses")}
      to="/studentsexpenses"
      icon={<FamilyRestroomIcon />}
      selected={selected}
      setSelected={setSelected}
      permission="all-student_grade_expenses"
    />,
    <Item
      title={t("StudentInstallments")}
      to="/StudentInstallments"
      icon={<PaymentIcon />}
      selected={selected}
      setSelected={setSelected}
      permission="all-installments"
    />,
  ];
  const management = [
    <Item
              title={t("Responsibility")}
              to="/Responsibility"
              icon={<AdminPanelSettings />}
              selected={selected}
              setSelected={setSelected}
              permission="all-role"
            />,
            <Item
              title={t("Administration Staff")}
              to="/adminstaff"
              icon={<EngineeringIcon />}
              selected={selected}
              setSelected={setSelected}
              permission="all-admin_employee"
            />,
            <Item
              title={t("Social Specialist")}
              to="/socialSpecialist"
              icon={<Man4Icon />}
              selected={selected}
              setSelected={setSelected}
              permission="all-social_specialist"
            />,
            <Item
              title={t("currencies")}
              to="/currencies"
              icon={<AttachMoneyIcon />}
              selected={selected}
              setSelected={setSelected}
              permission="all-currency"
            />,
            <Item
              title={t("School Information")}
              to="/schoolinfo"
              icon={<SettingsIcon />}
              selected={selected}
              setSelected={setSelected}
              permission="show-school_info"
            />
  ]
  return (
    <Box
      sx={{
        position: "stiky",
        display: "flex",
        // height: "100vh",
        top: 0,
        bottom: 0,
        zIndex: 10000,
        "& .sidebar": {
          border: "none",
        },
        "& .menu-icon": {
          backgroundColor: "transparent !important",
        },
        "& .menu-item": {
          backgroundColor: "transparent !important",
        },
        "& .menu-anchor": {
          color: "inherit !important",
          backgroundColor: "transparent !important",
        },
        "& .menu-item:hover": {
          color: `${colors.blueAccent[500]} !important`,
          backgroundColor: "transparent !important",
        },
        "& .menu-item.active": {
          color: `${colors.greenAccent[500]} !important`,
          backgroundColor: "transparent !important",
        },
      }}
    >
      <Sidebar
        breakPoint="md"
        rtl={sidebarRTL}
        backgroundColor={colors.primary[400]}
      >
        <Menu iconshape="square">
          <MenuItem
            icon={
              collapsed && <MenuOutlined onClick={() => collapseSidebar()} />
            }
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!collapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <img
                  src={
                    localStorage.getItem("color-mode") === "dark"
                      ? "/assets/left_login_logo.png"
                      : "/assets/Group 3277.png"
                  }
                  alt="logo"
                  width="27px"
                  height="30px"
                  style={{
                    margin: "0px 10px",
                    "@media (maxWidth:600px)": { margin: "0px 12px" },
                  }}
                />

                <Typography variant="h2" style={{ color: colors.grey[100] }}>
                  Educator
                </Typography>

                <IconButton
                  onClick={
                    broken ? () => toggleSidebar() : () => collapseSidebar()
                  }
                >
                  <CloseOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>
          <Box paddingLeft={collapsed ? undefined : "10%"}>
            <MenuItem
              active={selected === "Dashboard"}
              onClick={() => setSelected("Dashboard")}
              icon={<School />}
              routerLink={<Link to="/dashboard" />}
              style={{
                color: colors.grey[100],
              }}
            >
              <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
                {t("Dashboard")}
              </Typography>
            </MenuItem>

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 20px 5px 20px" }}
            >
              <hr />
            </Typography>

            <Item
              title={t("All_Classes")}
              to="/classes"
              icon={<Class />}
              selected={selected}
              setSelected={setSelected}
              permission="all-class"
            />
            <Item
              title={t("Grades")}
              to="/grades"
              icon={<Grade />}
              selected={selected}
              setSelected={setSelected}
              permission="all-grade"
            />
            <Item
              title={t("Subjects")}
              to="/subjects"
              icon={<Subject />}
              selected={selected}
              setSelected={setSelected}
              permission="all-subject"
            />
            <CustomAccordion
              options={StudentManagment}
              title={t("student_managment")}
            />
            <CustomAccordion
              options={ExamManagment}
              title={t("exam_managment")}
            />
            <CustomAccordion
              options={expensesManagment}
              title={t("Expenses_managment")}
            />

            <CustomAccordion options={options} title={t("Study_Plane")} />
            <CustomAccordion options={management} title={t("Management")} />

            
          </Box>
        </Menu>
      </Sidebar>
    </Box>
  );
};

export default MyProSidebar;
