import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import { MyProSidebarProvider } from "./pages/global/sidebar/sidebarContext";
import Topbar from "./pages/global/Topbar";
import Login from "./components/Login/login";
import { useSelector } from "react-redux";
import { ProtectedRoute } from "./protected_route/protected_route";
import Profile from "./pages/profile/profile";
import Classes from "./pages/Classes";
import DashBoard from "./pages/dashboard";
import AdminStaff from "./pages/adminStaff";
import Responsibility from "./pages/responsibility";
import Student_Details from "./pages/Student_Detailes/Student_Details";
import { useLocation } from "react-router-dom";
import Grades from "./pages/Grades/Grades";
import Subjects from "./pages/Subjects/Subjects";
import StudyPlane from "./pages/studyPlan";
import AddBans from "./pages/Bans/AddBans";
import AddGuardians from "./pages/Guardians/AddGuardians";
import AddAppreciations from "./pages/Appreciations/AddAppreciations";
import SchoolInformation from "./pages/School_Information/SchoolInformation";
import Parents from "./pages/parents";
import SocialSpecialist from "./pages/socialSpecialist/socialSpecialistTable";
import AbsenceTable from "./pages/Absence/table";
import FullMarks from "./pages/FullMarks";
import StudentsTable from "./pages/Student/StudentsTable";
import AddStudents from "./pages/Student/AddStudents";
import EditStudents from "./pages/Student/EditStudents";
import Exams from "./pages/Exams";
import ExamAssignmentTable from "./pages/ExamAssignment/table";
import ReportPage from "./pages/Reports/AddReports";
import ErrorPage from "./pages/Error/ErrorPage";
import { useEffect } from "react";
import Allterms from "./pages/Terms";
import Expenses from "./pages/Expenses";
import OneGradeExpenses from "./pages/OneGradeExpenses/OneGradeExpenses";
import StudentsExpensesTable from "./pages/studentsexpenses";
import ExamScheduleTable from "./pages/Exam Schedule/examSchedule";
import StudentInstallmentsTable from "./pages/StudentInstallments/StudentInstallmentsTable";
import ShowStudentInstallmentsTable from "./pages/StudentInstallments/ShowStudentInstallmentsTable";
import Currencies from "./pages/currencies";
import { InstallmentInvoice } from "./pages/StudentInstallments/pdfComponent";
import { InstallmentGradeInvoice } from "./pages/OneGradeExpenses/pdfComponent";
const App = () => {
  const [theme, colorMode] = useMode();
  const loggedIn = useSelector((state) => state.user.loggedIn);
  const location = useLocation();
  const isLoginPage = location.pathname === "/";

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", colorMode.mode);
  }, []);

  const invoicePattern = new RegExp(/.*(studentInvoice|gradeInvoice)\/*/)
  
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {isLoginPage ? (
          <main>
            <Routes>
              <Route path="/" element={<Login />} />
            </Routes>
          </main>
        ) : loggedIn ? (
          <>
              {!invoicePattern.test(window.location.href) ?(
              <MyProSidebarProvider>
                <div style={{ height: "100%", width: "100%" }}>
                  <main>
                    <Topbar />
                    <Routes>
                      <Route
                        path="/profile"
                        element={
                          <ProtectedRoute loggedIn={loggedIn}>
                            <Profile />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/adminstaff"
                        element={
                          <ProtectedRoute loggedIn={loggedIn}>
                            <AdminStaff />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/terms"
                        element={
                          <ProtectedRoute loggedIn={loggedIn}>
                            <Allterms />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/expenses"
                        element={
                          <ProtectedRoute loggedIn={loggedIn}>
                            <Expenses />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/currencies"
                        element={
                          <ProtectedRoute loggedIn={loggedIn}>
                            <Currencies />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/showGradeExpenses"
                        element={
                          <ProtectedRoute loggedIn={loggedIn}>
                            <OneGradeExpenses />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/studentsexpenses"
                        element={
                          <ProtectedRoute loggedIn={loggedIn}>
                            <StudentsExpensesTable />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/ExamSchedule"
                        element={
                          <ProtectedRoute loggedIn={loggedIn}>
                            <ExamScheduleTable />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/Responsibility"
                        element={
                          <ProtectedRoute loggedIn={loggedIn}>
                            <Responsibility />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/reports"
                        element={
                          <ProtectedRoute loggedIn={loggedIn}>
                            <ReportPage />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/studentdetails/:student_id"
                        element={
                          <ProtectedRoute loggedIn={loggedIn}>
                            <Student_Details />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/student-installments-info/:student_id"
                        element={
                          // <SelectedStudentIdProvider>
                          <ProtectedRoute loggedIn={loggedIn}>
                            <ShowStudentInstallmentsTable />
                          </ProtectedRoute>
                          // </SelectedStudentIdProvider>
                        }
                      />
                      <Route
                        path="/StudentInstallments"
                        element={
                          <ProtectedRoute loggedIn={loggedIn}>
                            <StudentInstallmentsTable />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/grades"
                        element={
                          <ProtectedRoute loggedIn={loggedIn}>
                            <Grades />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/fullmarks"
                        element={
                          <ProtectedRoute loggedIn={loggedIn}>
                            <FullMarks />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/subjects"
                        element={
                          <ProtectedRoute loggedIn={loggedIn}>
                            <Subjects />
                          </ProtectedRoute>
                        }
                      />
                      <Route path="*" element={<ErrorPage />} />
                      <Route
                        path="/bans"
                        element={
                          <ProtectedRoute loggedIn={loggedIn}>
                            <AddBans />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/guardians"
                        element={
                          <ProtectedRoute loggedIn={loggedIn}>
                            <AddGuardians />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/appreciations"
                        element={
                          <ProtectedRoute loggedIn={loggedIn}>
                            <AddAppreciations />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/absence"
                        element={
                          <ProtectedRoute loggedIn={loggedIn}>
                            <AbsenceTable />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/schoolinfo"
                        element={
                          <ProtectedRoute loggedIn={loggedIn}>
                            <SchoolInformation />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/studyplane"
                        element={
                          <ProtectedRoute loggedIn={loggedIn}>
                            <StudyPlane />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/parents"
                        element={
                          <ProtectedRoute loggedIn={loggedIn}>
                            <Parents />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/exams"
                        element={
                          <ProtectedRoute loggedIn={loggedIn}>
                            <Exams />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/socialSpecialist"
                        element={
                          <ProtectedRoute loggedIn={loggedIn}>
                            <SocialSpecialist />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/classes"
                        element={
                          <ProtectedRoute loggedIn={loggedIn}>
                            <Classes />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/dashboard"
                        element={
                          <ProtectedRoute loggedIn={loggedIn}>
                            <DashBoard />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/studyplane"
                        element={
                          <ProtectedRoute loggedIn={loggedIn}>
                            <StudyPlane />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/student"
                        element={
                          <ProtectedRoute loggedIn={loggedIn}>
                            <StudentsTable />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/add-student"
                        element={
                          <ProtectedRoute loggedIn={loggedIn}>
                            <AddStudents />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/edit-student/:id"
                        element={
                          <ProtectedRoute loggedIn={loggedIn}>
                            <EditStudents />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/exam-assignment"
                        element={
                          <ProtectedRoute loggedIn={loggedIn}>
                            <ExamAssignmentTable />
                          </ProtectedRoute>
                        }
                      />
                    </Routes>
                  </main>
                </div>
              </MyProSidebarProvider>
            ) : (
              <Routes>
                <Route
                  path="/studentInvoice/:student_id"
                  element={<InstallmentInvoice />}
                />
                <Route
                  path="/gradeInvoice/:grade_id"
                  element={<InstallmentGradeInvoice />}
                />
              </Routes>
            )}
          </>
        ) : (
          <Routes>
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        )}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
