import React, { useState } from "react";
import { Box, ButtonGroup, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteFullMark from "./DeleteFullMark";
import { useTranslation } from "react-i18next";
import DropDownExams from "./DropDownExams";
import AddFullMark from "./AddFullMark";
import EditFullMark from "./EditFullMark";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import CustomTableBox from "../../components/customTableBox/CustomTableBox";
import { fetchFullMarkDataByPage } from "../../redux/FullMarks";
import CustomPagenation from "../../components/CustomPagenation/CustomPagenation";

const FullMarksTable = () => {
  const nextPage = useSelector((state) => state.FullMarks.fullMarkLinks.next);
  const lastPage = useSelector((state) => state.FullMarks.fullMarkLinks.last);
  const prevPage = useSelector((state) => state.FullMarks.fullMarkLinks.prev);
  const currentPage = useSelector((state) => state.FullMarks.currentPage);
  const firstPage = useSelector((state) => state.FullMarks.fullMarkLinks.first);
  const { sidebarRTL } = useSidebarContext();
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(10);
  const loading = useSelector((state) => state.FullMarks.loading);
  const fullMarkData = useSelector(
    (state) => state.FullMarks.fullMarkData.data||[]
  );
  const [examId, setExamId] = useState();
  const theme = useTheme();
  const permission =
    useSelector((state) => state.user.userInfo.data.permissions) || [];
  const colors = tokens(theme.palette.mode);
  const subjectWidth =
    permission.includes("update-custom_full_marks") &&
    permission.includes("delete-custom_full_marks")
      ? 250
      : 400;
  const GradeWidth =
    permission.includes("update-custom_full_marks") &&
    permission.includes("delete-custom_full_marks")
      ? 150
      : 400;
  const columns = [
    { field: "id", headerName: t("ID"), width: 120 },
    {
      field: "gradeName",
      headerName: t("grade Name"),
      width: GradeWidth,
      cellClassName: "name-column--cell",
    },
    {
      field: "subjectName",
      headerName: t("Subject Name"),
      width: subjectWidth,
      cellClassName: "name-column--cell",
    },
    { field: "fullMark", headerName: t("Full Mark"), width: 120 },
    ...(permission.includes("delete-custom_full_marks") &&
    permission.includes("update-custom_full_marks")
      ? [
          {
            field: "actions",
            headerName: t("Actions"),
            width: 400,
            renderCell: (params) => (
              <ButtonGroup
                sx={{
                  display: "flex",
                  flexDirection: sidebarRTL
                    ? "row-reverse !important"
                    : "row !important",
                  alignItems: "center",
                }}
              >
                <EditFullMark
                  sx={{
                    backGround: `${colors.grey[600]}`,
                    display: permission.includes("update-custom_full_marks")
                      ? "inline-flex"
                      : "none",
                  }}
                  fullMark={params.row.fullMark}
                  ID={params.id}
                  icon={<EditIcon />}
                  examId={examId}
                />
                <DeleteFullMark
                  sx={{
                    background: `${colors.redAccent[600]}`,
                    display: permission.includes("delete-custom_full_marks")
                      ? "inline-flex"
                      : "none",
                  }}
                  icon={<DeleteIcon />}
                  id={params.id}
                  examId={examId}
                />
              </ButtonGroup>
            ),
          },
        ]
      : []),
  ];
  const tableData = {
    rows: fullMarkData.length > 0 &&  fullMarkData,
    columns: columns,
    loading: loading && true,
    pageSize: pageSize,
    onPageSizeChange: (newPageSize) => setPageSize(newPageSize),
  };
  return (
    <Box m="20px">
      <Box
        display="flex"
        dir={sidebarRTL ? "rtl" : "ltr"}
        justifyContent="space-between"
        alignItems="center"
      >
        <Header title={t("full_marks")} />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={sidebarRTL ? "row" : "row-reverse"}
      >
        <AddFullMark
          show={
            permission.includes("store-custom_full_marks")
              ? "inline-flex"
              : "none"
          }
          exam_id={examId}
          pageSize={pageSize}
        />
        <DropDownExams
          pageSize={pageSize}
          setExamId={setExamId}
          examId={examId}
        />
      </Box>
      <CustomTableBox
        tableData={tableData}
        CustomPagenation={
          <CustomPagenation
            pageSize={pageSize}
            nextPage={nextPage}
            lastPage={lastPage}
            prevPage={prevPage}
            currentPage={currentPage}
            firstPage={firstPage}
            action={fetchFullMarkDataByPage}
          />
        }
      />
    </Box>
  );
};
export default FullMarksTable;
