import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import {
  getExams,
  getFullMarkGrades,
  getFullMarkSubjects,
} from "../../redux/FullMarks";
import {
  Select,
  FormControl,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSidebarContext } from "../global/sidebar/sidebarContext";

const DropDownGradesSubjects = ({ setFormikValues }) => {
  const {t} = useTranslation()
  const [selectedGradeId, setSelectedGradeId] = useState();
  const [selectedSubjectId, setSelectedSubjectId] = useState();
  const [selectedExamId, setSelectedExamId] = useState(null);
  const AllGrades =
    useSelector((state) => state.FullMarks.getGradesData.data) || [];
  const AllSubjects =
    useSelector((state) => state.FullMarks.getSubjectsData.data) || [];
  const exams = useSelector((state) => state.FullMarks.getExamsData.data) || [];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFullMarkGrades());
    dispatch(getExams());
  }, [dispatch]);

  useEffect(() => {
    if (selectedGradeId) {
      dispatch(getFullMarkSubjects({ selectedGradeId }));
    }
  }, [dispatch, selectedGradeId]);

  const handleSelectChangeGrade = (event) => {
    const selectedGradeId = event.target.value;
    setSelectedGradeId(selectedGradeId);
    setSelectedSubjectId(null);
    setSelectedExamId(null);
    setFormikValues("grade_id", selectedGradeId);
  };

  const handleSelectChangeSubject = (event) => {
    setSelectedSubjectId(event.target.value);
    setSelectedExamId(null);
    setFormikValues("subject_id", event.target.value);
  };

  const handleSelectChangeExam = (event) => {
    setSelectedExamId(event.target.value);
    setFormikValues("exam_id", event.target.value);
  };

  const { sidebarRTL } = useSidebarContext();

  return (
    <Box m="20px" display="flex" flexDirection="column" dir={sidebarRTL?"rtl":"ltr"}>
      <FormControl variant="outlined">
        <lable style={{fontSize:"18px",fontWeight: "Bold"}}>{t("Grade")}</lable>
        <Select
          fullWidth
          value={selectedGradeId}
          onChange={handleSelectChangeGrade}
          dir={sidebarRTL ? "rtl" : "ltr"}
        >
          {AllGrades &&
            AllGrades.map((gradeItem) => (
              <MenuItem dir={sidebarRTL ? "rtl" : "ltr"}
                key={gradeItem.id} value={gradeItem.id}>
                {gradeItem.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined" sx={{ mt: 2 }}>
        <lable style={{fontSize:"18px",fontWeight: "Bold"}}>{t("Subject")}</lable>
        <Select
          fullWidth
          disabled={!selectedGradeId}
          value={selectedSubjectId}
          onChange={handleSelectChangeSubject}
          dir={sidebarRTL ? "rtl" : "ltr"}
        >
          {AllSubjects &&
            AllSubjects.map((subjectItem) => (
              <MenuItem dir={sidebarRTL ? "rtl" : "ltr"}
                key={subjectItem.id} value={subjectItem.id}>
                {subjectItem.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined" sx={{ mt: 2 }}>
      <lable style={{fontSize:"18px",fontWeight: "Bold"}}>{t("Exams")}</lable>
        <Select
          fullWidth
          disabled={!selectedSubjectId}
          value={selectedExamId}
          onChange={handleSelectChangeExam}
          dir={sidebarRTL ? "rtl" : "ltr"}
        >
          {exams &&
            exams.map((examItem) => (
              <MenuItem dir={sidebarRTL ? "rtl" : "ltr"} key={examItem.id} value={examItem.id}>
                {examItem.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default DropDownGradesSubjects;
